import Button from "./UI/Button";
import { IoChevronDownOutline } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";
// import { statusData, statusTypes } from "../helpers/order";
import { memo, useCallback } from "react";
import { editOrderStatus } from "../services/order";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { convertColor } from "../helpers/convertColor";
import { useTranslation } from "react-i18next";

const isStatus = (statuses = []) => {
  var data = useSelector((state) => state.status.data);
  if (!statuses || statuses?.length === 0) {
    return false;
  }
  var active = statuses.find((e) => e.active)?.status;

  if (active?.value == "new") {
    active.color = "#00ab55";
  }
  if (active?.value == "done") {
    active.color = "#222";
  }
  return { data, active };
};

const Status = memo((item) => {
  const { t } = useTranslation();
  const statuses = item?.data?.statuses ?? item?.data ?? [];

  const { data, active } = isStatus(statuses);

  const onSubmit = useCallback((data) => {
    if (!item.data) {
      NotificationManager.error("Нет данные заказа");
    }
    if (!data) {
      NotificationManager.error("Нет статуса заказа");
    }
    if (item.onChange) {
      item.onChange(data);
    }

    item.data.status = data;

    editOrderStatus(item.data);
  }, []);

  const CustomToggle = memo(({ children, onClick }) => (
    <Button
      disabled={item.disabled ?? false}
      onClick={(e) => {
        e.preventDefault();
        !item.disabled && onClick(e);
      }}
      className={
        "w-100 btn-status-order d-flex align-items-center justify-content-between" +
        (item?.classNameBtn ? " " + item.classNameBtn : "")
      }
      style={
        active?.color && {
          color: active.color,
          backgroundColor: convertColor(active.color, 0.08),
        }
      }
    >
      <span>{t(children)}</span>
      <span className="ms-2">
        <IoChevronDownOutline size={15} />
      </span>
    </Button>
  ));

  return (
    <Dropdown className={item?.className ? " " + item.className : ""}>
      <Dropdown.Toggle as={CustomToggle} id="status-order">
        {t(active?.name ?? item.emptyText ?? "Не указано")}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100" align="end">
        {data &&
          data.map((e, index) => {
            return (
              <Dropdown.Item
                disabled={
                  !!statuses.find((e2) => e2.statusId === e.id)?.end ||
                  !!statuses.find((e2) => e2.statusId === e.id)?.active
                }
                key={index}
                active={statuses.find((e2) => e2.statusId === e.id)?.active}
                onClick={() => onSubmit(e)}
                className="fs-08"
              >
                {t(e?.name ?? "Не указано")}
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
});
export { Status, isStatus };
