import axios from "axios";
import { ClientJS } from "clientjs";

const createCallback = async (data) => {

  const client = new ClientJS();

  const referrer = document.referrer;
  const language = client.getLanguage();

  const DEVICE = {
    brand: client.getBrowser() ?? "",
    osName: client.getOS() ?? "",
    osVersion: client.getOSVersion() ?? "",
    language: language ?? "ru_RU",
  };

  const response = await axios.postForm(
    "https://api.telegram.org/bot6443598489:AAFfFEzRRV3yYIoGwEfmVVkh7ZpNmA41lqU/sendMessage",
    {
      chat_id: "-1001846556129",
      text: `#заявка_yooapp\nИмя: ${data.name}\nНомер телефона: ${
        data.phone
      }\nИнформация: ${DEVICE.brand} ${DEVICE.osName} ${DEVICE.osVersion} ${
        DEVICE.language
      }\nИсточник: ${
        data?.source && data?.source?.length > 0
          ? data.source
          : referrer ?? "Нет"
      }\nСтрана: ${
        data?.country?.name ?? data?.options?.defaultCountry ?? "Нет"
      }\nГород: ${data?.options?.defaultCity ?? "Нет"}\nIP: ${
        data?.ip ?? "Нет"
      }\n@central_planet @art_plaix @Evgeniy_app`,
    }
  );
  return response?.data;
};

export { createCallback };
