import React, { useCallback, useLayoutEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import { editStatus, getStatus } from "../../services/status";
const optionsIiko = [
  { title: "Неподтверждено", value: "unconfirmed", subTitle: "unconfirmed" },
  {
    title: "Ожидание приготовления",
    value: "waitcooking",
    subTitle: "waitcooking",
  },
  {
    title: "Готово к приготовлению",
    value: "readyforcooking",
    subTitle: "readyforcooking",
  },
  {
    title: "Приготовление начато",
    value: "cookingstarted",
    subTitle: "cookingstarted",
  },
  {
    title: "Приготовление завершено",
    value: "cookingcompleted",
    subTitle: "cookingcompleted",
  },
  { title: "Ожидание", value: "waiting", subTitle: "waiting" },
  { title: "В пути", value: "onway", subTitle: "onway" },
  { title: "Доставлено", value: "delivered", subTitle: "delivered" },
  { title: "Закрыто", value: "closed", subTitle: "closed" },
  { title: "Отменено", value: "cancelled", subTitle: "cancelled" },
];

const StatusEdit = () => {
  const { statusId } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  useLayoutEffect(() => {
    getStatus(statusId)
      .then(
        (res) =>
          res &&
          reset((prev) => ({
            ...prev,
            ...res,
          }))
      )
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback(() => {
    function parseStringToArray(str) {
      if (!str || typeof str !== "string") return [];
      return str
        .split(",") // Разбиваем строку по запятой
        .map((item) => item.trim()) // Убираем пробелы вокруг каждого элемента
        .filter((item) => item !== ""); // Удаляем пустые элементы
    }
    if (!data.type) {
      data.apiId = parseStringToArray(data.apiId);
    }

    editStatus({ ...data, id: statusId })
      .then(() => {
        NotificationManager.success("Статус успешно обновлен");
        navigate(-1);
      })
      .catch(
        (err) =>
          err && NotificationManager.error("Ошибка при сохранении статуса")
      );
  }, [data, statusId]);

  // const onEditApiId = useCallback(
  //   (e) => {
  //     if (data.type === "iiko") {
  //       if (!optionsIiko.find((item) => item === e.value)) {
  //         setValue(
  //           "apiId",
  //           data.apiId?.length > 0 ? [...data.apiId, e.value] : [e.value]
  //         );
  //       } else {
  //         setValue(
  //           "apiId",
  //           data.apiId.filter((item) => item !== e.value)
  //         );
  //       }
  //     }
  //   },
  //   [data, optionsIiko]
  // );
  console.log(data.apiId);
  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такого статуса нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title={data.name ?? "Статус"} />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/options/statuses"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button onClick={() => handleSubmit(onSubmit())}>
          Сохранить изменения
        </Button>
      </div>
      <h3 className="mb-4">Редактирование статуса</h3>
      <Row>
        <Col md={4}>
          <div className="mb-3">
            <Input
              label="Название"
              name="name"
              errors={errors}
              register={register}
              validation={{
                required: "Обязательное поле",
              }}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <Input
              readOnly={false}
              label="Значением"
              name="value"
              errors={errors}
              register={register}
              validation={{
                required: "Обязательное поле",
              }}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <Input
              label="Цвет"
              name="color"
              errors={errors}
              register={register}
              validation={{
                required: "Обязательное поле",
              }}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <Input
              defaultValue={data.order ?? 0}
              label="Порядок"
              name="order"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <Select
              label="Статус"
              onClick={(e) => setValue("status", e.value)}
              value={data?.status}
              data={[
                { title: "Активно", value: 1 },
                { title: "Отключено", value: 0 },
              ]}
            />
          </div>
        </Col>
        <Col>
          <Form.Check className="mb-4">
            <Form.Check.Input
              type="checkbox"
              name="end"
              id="end"
              defaultChecked={getValues("end")}
              {...register("end")}
            />
            <Form.Check.Label htmlFor="end" className="ms-2">
              Конечный статус
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
      <h5 className="mb-4">Интеграция</h5>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Select
              label="Тип"
              placeholder="Выберите статус"
              onClick={(e) => setValue("type", e.value)}
              value={data.type}
              data={[
                { title: "Произвольно", value: "" },
                { title: "Iiko", value: "iiko" },
              ]}
            />
          </div>
          {data.type === "iiko" ? (
            optionsIiko && (
              <div>
                {optionsIiko.map((item) => {
                  let info = data.apiId
                    ? data.apiId.includes(item.value)
                    : false;
                  return (
                    <Form.Check className="mb-3 d-flex align-items-center">
                      <Form.Check.Input
                        type="checkbox"
                        name="apiId"
                        id={"apiid-" + item.value}
                        defaultChecked={!!info}
                        value={item.value}
                        {...register("apiId")}
                      />
                      <Form.Check.Label
                        htmlFor={"apiid-" + item.value}
                        className="ms-2 d-flex flex-column"
                      >
                        <p className="fs-09 fw-6">{item.title}</p>
                        <p className="fs-07 text-muted">{item.subTitle}</p>
                      </Form.Check.Label>
                    </Form.Check>
                  );
                })}
              </div>
            )
          ) : (
            <div className="mb-3">
              <Input
                label="Интеграция через запятую (id)"
                name="apiId"
                errors={errors}
                register={register}
              />
            </div>
          )}
        </Col>
      </Row>
      <h5 className="mb-4">Настройки уведомления</h5>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Input
              label="Заголовок"
              name="notificationTitle"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Input
              label="Описание"
              name="notificationDesc"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default StatusEdit;
