import Howler from "howler";
import React, { useCallback, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoPauseCircleOutline, IoPlayCircleOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import { audioList } from "../../helpers/all";
import { getRoleData, roles } from "../../helpers/member";
import { createMember } from "../../services/member";

const MemberCreate = () => {
  const navigate = useNavigate();
  const affiliate = useSelector((state) => state.affiliate);
  const [btnLoading, setBtnLoading] = useState(false);
  const affiliatesData =
    affiliate?.data?.length > 0
      ? affiliate.data.filter((e, index) => index !== 0)
      : [];
  const [audio, setAudio] = useState(false);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onSubmit",
    defaultValues: {
      groupRole: 1,
      role: 1,
      affiliateId: "",
    },
  });
  const form = useWatch({ control });

  const handlePlay = (index) => {
    if (audio?.data) {
      audio.data.stop();
    }
    let audioClick = new Howler.Howl({
      src: [audioList[index].src],
      loop: false,
      volume: 1,
      onend: () => {
        setAudio(false);
      },
    });
    audioClick.play();
    setAudio({ data: audioClick, index });
  };

  const handleStop = () => {
    if (audio?.data) {
      audio.data.stop();
      setAudio(false);
    }
  };

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    createMember(data)
      .then(() => {
        NotificationManager.success("Сотрудник успешно создан");
        navigate("/options/members");
      })

      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      )
      .finally(() => setBtnLoading(false));
  }, []);

  return (
    <>
      <Meta title="Добавление сотрудника" />
      <h3 className="mb-4">Добавление сотрудника</h3>
      <Card body>
        <Row>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Номер телефона"
                name="phone"
                placeholder="+7(000)000-00-00"
                mask="+7(999)999-99-99"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                type="date"
                label="День рождения"
                placeholder="Введите день рождения (Необязательно)"
                name="brithday"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={4}>
            <Select
              className="mb-4"
              label="Пол"
              onClick={(e) => setValue("sex", e.value)}
              value={form.sex ?? ""}
              data={[
                { title: "Не указано", value: "" },
                { title: "Мужской", value: "man" },
                { title: "Женский", value: "woman" },
              ]}
            />
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Фамилия"
                name="lastName"
                placeholder="Введите фамилию (Необязательно)"
                errors={errors}
                register={register}
                validation={{
                  maxLength: {
                    value: 50,
                    message: "Максимально 50 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Имя"
                name="firstName"
                errors={errors}
                register={register}
                placeholder="Введите имя"
                validation={{
                  required: "Введите имя",
                  maxLength: {
                    value: 20,
                    message: "Максимально 20 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                label="Отчество"
                name="patronymic"
                autocomplete="off"
                errors={errors}
                register={register}
                placeholder="Введите отчество (Необязательно)"
                validation={{
                  maxLength: {
                    value: 50,
                    message: "Максимально 50 символов",
                  },
                }}
              />
            </div>
          </Col>
        </Row>
        <h5 className="mb-4">Настройки</h5>
        <Row>
          <Col md={6}>
            <Select
              className="mb-4"
              label="Роль"
              onClick={(e) => setValue("groupRole", e.value)}
              value={form.groupRole}
              data={roles}
            />
          </Col>
          <Col md={6}>
            <Select
              className="mb-4"
              label="Должность"
              onClick={(e) => setValue("role", e.value)}
              value={form.role}
              data={getRoleData(form.groupRole)}
            />
          </Col>
        </Row>
        <h5 className="mb-4">Данные для входа</h5>
        <Row>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Email"
                name="email"
                placeholder="Введите email"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Пароль"
                name="password"
                type="password"
                errors={errors}
                placeholder="Введите пароль"
                register={register}
                validation={{
                  maxLength: {
                    value: 200,
                    message: "Максимально 200 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Повторный пароль"
                name="passwordConfirm"
                type="password"
                errors={errors}
                placeholder="Введите повторно пароль"
                register={register}
                validation={{
                  maxLength: {
                    value: 200,
                    message: "Максимально 200 символов",
                  },
                }}
              />
            </div>
          </Col>
        </Row>
        <h5 className="mb-4">Паспортные данные</h5>
        <Row>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Серия и номер"
                name="passport.number"
                errors={errors}
                register={register}
                placeholder="Введите серию и номер"
                validation={{
                  maxLength: {
                    value: 20,
                    message: "Максимально 20 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                type="date"
                label="Дата регистрации"
                placeholder="Введите дату регистрации"
                name="passport.reg"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Место регистрации"
                name="passport.place"
                errors={errors}
                register={register}
                placeholder="Введите место регистрации"
                validation={{
                  maxLength: {
                    value: 250,
                    message: "Максимально 250 символов",
                  },
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h5 className="mb-3">Филиалы</h5>
            <div className="box box-gray mb-3">
              <div className="body-scroll">
                <Form.Check key={0} className="mb-3 d-flex align-items-center">
                  <Form.Check.Input
                    type="checkbox"
                    name="affiliates"
                    id={"affiliates-0"}
                    checked={
                      form.affiliates?.length > 0 &&
                      affiliatesData?.length > 0 &&
                      form.affiliates?.length === affiliatesData?.length
                    }
                    onChange={() => {
                      if (
                        form.affiliates?.length > 0 &&
                        affiliatesData?.length > 0 &&
                        form.affiliates?.length === affiliatesData?.length
                      ) {
                        setValue("affiliates", []);
                      } else {
                        setValue(
                          "affiliates",
                          affiliatesData.map((e) => e.id)
                        );
                      }
                    }}
                  />
                  <Form.Check.Label
                    htmlFor={"affiliates-0"}
                    className="ms-3 d-flex flex-column"
                  >
                    Все
                  </Form.Check.Label>
                </Form.Check>
                {affiliatesData?.length > 0 &&
                  affiliatesData
                    .sort((a, b) => a.title && a.title.localeCompare(b.title))
                    .map((affiliate, index) => (
                      <Form.Check
                        key={index}
                        className="mb-3 d-flex align-items-center"
                      >
                        <Form.Check.Input
                          type="checkbox"
                          name="affiliates"
                          id={"affiliates-" + affiliate.id}
                          value={affiliate.id}
                          checked={
                            form?.affiliates?.length > 0 &&
                            form?.affiliates?.includes(affiliate.id)
                          }
                          onChange={() => {
                            if (
                              form?.affiliates?.length > 0 &&
                              form?.affiliates?.includes(affiliate.id)
                            ) {
                              setValue(
                                "affiliates",
                                form?.affiliates.filter(
                                  (e) => e != affiliate.id
                                )
                              );
                            } else {
                              setValue(
                                "affiliates",
                                form?.affiliates?.length > 0
                                  ? [...form?.affiliates, affiliate.id]
                                  : [affiliate.id]
                              );
                            }
                          }}
                        />
                        <Form.Check.Label
                          htmlFor={"affiliates-" + affiliate.id}
                          className="ms-3 d-flex flex-column"
                        >
                          <div className="fs-09 fw-6">
                            {affiliate.title ?? affiliate.full}
                          </div>
                          {affiliate.title && (
                            <p className="fs-08 text-muted">{affiliate.full}</p>
                          )}
                        </Form.Check.Label>
                      </Form.Check>
                    ))}
              </div>
            </div>
          </Col>
          <Col md={6}>
            <h5 className="mb-4">Доступы</h5>
            <Form.Check className="mb-3 d-flex align-items-center">
              <Form.Check.Input
                type="checkbox"
                name="options.access.all"
                id="access-all"
                {...register("options.access.all")}
              />
              <Form.Check.Label
                htmlFor="access-all"
                className="ms-3 d-flex flex-column"
              >
                Полный доступ
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col md={6}>
            <h5 className="mb-3 fw-6 h6">Звук и уведомления</h5>
            <div>
              {audioList.map((e) => (
                <div
                  key={e.id}
                  className="d-flex flex-row align-items-center mb-2"
                >
                  <Form.Check>
                    <Form.Check.Input
                      type="checkbox"
                      onChange={() => setValue("options.audio", e.id)}
                      checked={form.options?.audio === e.id}
                    />
                  </Form.Check>
                  <div className="d-flex w-100 flex-row justify-content-between align-items-center">
                    <a
                      className="ms-2 fs-09"
                      onClick={() => setValue("options.audio", e.id)}
                    >
                      {e.title}
                    </a>
                    {e.src && (
                      <a
                        onClick={() =>
                          audio?.index === e.id
                            ? handleStop(e.id)
                            : handlePlay(e.id)
                        }
                      >
                        {audio?.index === e.id ? (
                          <IoPauseCircleOutline
                            className="text-success"
                            size={26}
                          />
                        ) : (
                          <IoPlayCircleOutline
                            className="text-success"
                            size={26}
                          />
                        )}
                      </a>
                    )}
                  </div>
                </div>
              ))}
              <hr />
              <div className="d-flex flex-row align-items-center mb-2">
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    onChange={() =>
                      setValue("options.audioLoop", !form.options?.audioLoop)
                    }
                    checked={form.options?.audioLoop}
                  />
                </Form.Check>
                <div className="d-flex w-100 flex-row align-items-center">
                  <a
                    className="ms-2 fs-09"
                    onClick={() =>
                      setValue("options.audioLoop", !form.options?.audioLoop)
                    }
                  >
                    Зацикливать воспроизведение
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            isValid={isValid}
            isLoading={btnLoading}
            disabled={btnLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Сохранить изменения
          </Button>
        </div>
      </Card>
    </>
  );
};

export default MemberCreate;
