import { Map, Polygon, YMaps } from "@pbe/react-yandex-maps";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import { editZone, getZone } from "../../services/zone";

const statusData = [
  {
    title: "В работе",
    value: 1,
  },
  {
    title: "Отключено",
    value: 0,
  },
];

const MapView = memo(({ color, data, lat, lon }) => {
  const geodata =
    data?.length > 0 ? Array(data.map((geo) => [geo[1], geo[0]])) : false;
  return (
    <YMaps>
      <Map
        className="map"
        state={{
          center: [geodata[0][0][0] ?? lat, geodata[0][0][1] ?? lon],
          zoom: 10,
        }}
        width="100%"
        height="100%"
      >
        <Polygon
          geometry={geodata}
          options={{
            fillColor: color ? color : "#56db40",
            strokeColor: color ? color : "#56db40",
            opacity: 0.6,
            strokeWidth: 2,
            strokeStyle: "solid",
          }}
        />
      </Map>
    </YMaps>
  );
});

const EditZone = () => {
  const { zoneId } = useParams();
  const [loading, setLoading] = useState(true);
  const affiliates = useSelector((state) => state.affiliate.data)?.filter(
    (e) => e.id !== null
  );

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onSubmit",
    defaultValues: {
      time: 60,
      status: 1,
      price: 0,
      priceFree: 0,
      minPrice: 0,
      color: "#32bb30",
      options: {
        freeStatus: true,
        distance: true,
        preorderMin: 0,
      },
    },
  });

  const data = useWatch({ control });

  const mainAffiliate = data.affiliateId
    ? affiliates.find((e) => Number(e.id) === Number(data.affiliateId)) ??
      affiliates[0]
    : affiliates[0];

  useEffect(() => {
    getZone(zoneId)
      .then(
        (res) =>
          res &&
          reset((prev) => ({
            ...prev,
            ...res,
          }))
      )
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    editZone(data)
      .then(() => {
        NotificationManager.success("Зона доставки успешно обновлена");
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <>
        <Meta title="Зона доставки" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Такой зоны доставки нет</h3>
        </Info>
      </>
    );
  }

  return (
    <>
      <Meta title="Редактирование зоны доставки" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/options/affiliates/zones"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
      <h3 className="mb-4">Редактирование зоны доставки</h3>
      <Row>
        <Col md={6}>
          <Input
            className="mb-3"
            label="Название"
            name="title"
            errors={errors}
            defaultValue={data?.title}
            register={register}
            validation={{
              required: "Обязательное поле",
              maxLength: { value: 250, message: "Максимально 250 символов" },
            }}
          />
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Цена доставки"
                  name="price"
                  errors={errors}
                  defaultValue={data?.price ?? 0}
                  register={register}
                  validation={{
                    required: "Обязательное поле",
                    min: { value: 0, message: "Минимально 0" },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Мин сумма заказа"
                  name="minPrice"
                  min={0}
                  type="number"
                  errors={errors}
                  defaultValue={data?.minPrice ?? 0}
                  register={register}
                  validation={{
                    required: "Обязательное поле",
                    min: { value: 0, message: "Минимально 0" },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  label="Бесплатная доставка"
                  name="priceFree"
                  min={0}
                  errors={errors}
                  type="number"
                  defaultValue={data?.priceFree ?? 0}
                  register={register}
                  validation={{
                    required: "Обязательное поле",
                    min: { value: 0, message: "Минимально 0" },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  label="Приоритет"
                  name="priority"
                  min={0}
                  errors={errors}
                  type="number"
                  defaultValue={data?.priority ?? 0}
                  register={register}
                  validation={{
                    required: "Обязательное поле",
                    min: { value: 0, message: "Минимально 0" },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Статус"
                  onClick={(e) => setValue("status", e.value)}
                  value={data?.status}
                  data={statusData}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  type="number"
                  label="Время доставки"
                  name="time"
                  min={0}
                  defaultValue={data?.time ?? 0}
                  errors={errors}
                  register={register}
                  validation={{
                    required: "Обязательное поле",
                    min: { value: 0, message: "Минимально 0 мин" },
                    max: { value: 1000, message: "Минимально 1000 мин" },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Input
                label="Минимально минут предзаказа"
                type="number"
                min={0}
                max={500}
                defaultValue={0}
                errors={errors}
                name="options.preorderMin"
                register={register}
                validation={{
                  required: "Обязательное поле",
                  min: { value: 0, message: "Минимально 0 мин" },
                  max: { value: 500, message: "Минимально 500 мин" },
                }}
              />
              <p className="text-muted fs-08 mt-2 mb-3">
                Минимальное время в минутах до предзаказа
              </p>
            </Col>
            <Col md={12}>
              <div className="mb-2">
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    className="btn-sm"
                    id="freeStatus"
                    defaultChecked={data.options?.freeStatus}
                    onChange={(e) =>
                      setValue("options.freeStatus", e.target.checked)
                    }
                  />
                  <Form.Check.Label htmlFor="freeStatus" className="ms-2 fs-09">
                    Включить бесплатную доставку
                  </Form.Check.Label>
                </Form.Check>

                <Form.Check className="mb-3 d-inline-block">
                  <Form.Check.Input
                    type="checkbox"
                    className="btn-sm"
                    id="distance"
                    defaultChecked={data?.options?.distance}
                    onChange={(e) =>
                      setValue("options.distance", e.target.checked)
                    }
                  />
                  <Form.Check.Label htmlFor="distance" className="ms-2 fs-09">
                    Показать время доставки
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Select
                  label="Филиал"
                  onClick={(e) => setValue("affiliateId", e.value)}
                  value={data?.affiliateId}
                  data={
                    affiliates?.length > 0
                      ? affiliates.map((e) => ({
                          title: e.full,
                          value: e.id,
                          id: e.id,
                        }))
                      : []
                  }
                />
              </div>
            </Col>
            <Col md={12}>
              <h5 className="mb-3 fs-09">
                Цвет{" "}
                <span
                  className="badge badge-sm"
                  style={{ backgroundColor: data?.color ? data.color : "#eee" }}
                >
                  {data.color}
                </span>
              </h5>

              <Input
                type="color"
                defaultValue={data?.color}
                errors={errors}
                name="color"
                register={register}
              />
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          {mainAffiliate &&
            mainAffiliate?.options?.coordinates?.lat &&
            mainAffiliate?.options?.coordinates?.lon &&
            data?.data &&
            data?.data?.length > 0 && (
              <MapView
                color={data?.color}
                data={data.data}
                lan={mainAffiliate?.options?.coordinates?.lat}
                lon={mainAffiliate?.options?.coordinates?.lon}
              />
            )}
        </Col>
      </Row>
    </>
  );
};

export default EditZone;
