import React, { useCallback, useLayoutEffect, useState } from "react";
import { Col, Row, Card, Tabs, Tab, Form } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import CustomEditor from "../../components/editor/CustomEditor";
import Meta from "../../components/Meta";
import PreviewImages from "../../components/PreviewImages";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import { generateUrl } from "../../helpers/all";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { editBlog, getBlog } from "../../services/blog";

const BlogEdit = () => {
  const navigate = useNavigate();
  const { blogId } = useParams();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const affiliate = useSelector((state) => state.affiliate.data);
  const affiliatesData =
    affiliate?.length > 0 ? affiliate.filter((e, index) => index !== 0) : [];
  const [title, setTitle] = useState(null);

  const {
    control,
    register,
    formState: { errors, isValid },
    reset,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  useLayoutEffect(() => {
    getBlog(blogId)
      .then((res) => {
        reset((prev) => ({
          ...prev,
          ...res,
        }));
        setTitle(res?.title);
      })
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }

    editBlog(formData)
      .then(() => {
        NotificationManager.success("Новости успешно обновлены");
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        err && NotificationManager.error("Ошибка при сохранении новости");
      });
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такой новости нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title={title ?? "Редактирование новости"} />
      <div>
        <Link
          to="/content/blogs"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>{title ?? "Редактирование новости"}</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Tabs className="mb-3" fill>
            <Tab.Pane eventKey={0} title={t("Основное")}>
              <Row>
                <Col md={4} className="pb-3">
                  <DragDropFile
                    file={data.file}
                    onChange={(e) => setValue("file", e)}
                    onDelete={(e) => setValue("file", e.file)}
                  />
                  <PreviewImages
                    medias={data?.media}
                    type="blog"
                    col={12}
                    onDelete={(e) => {
                      setValue("media", e.media);
                      let newArrayDelete =
                        data?.delete?.length > 0
                          ? [...data.delete, e.delete]
                          : [e.delete];
                      setValue("delete", newArrayDelete);
                    }}
                  />
                </Col>
                <Col md={8}>
                  <div className="mb-3">
                    <Input
                      autoFocus={true}
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Input
                      defaultValue={data?.alias ?? generateUrl(data.title)}
                      label="Ссылка"
                      name="alias"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Введите ссылку",
                        minLength: {
                          value: 2,
                          message: "Минимально 2 символа",
                        },
                        maxLength: {
                          value: 255,
                          message: "Максимально 255 символов",
                        },
                        pattern: {
                          value: /^[a-z0-9_]+$/,
                          message: "Неверный формат (Только a-z0-9_)",
                        },
                      }}
                    />
                  </div>

                  <CustomEditor
                    content={data.content}
                    onChange={(e) => setValue("content", e)}
                  />
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey={1} title={t("Филиалы")}>
              <p className="mb-3 text-muted fs-09">
                Если ни один филиал не выбран, баннер будет показан во всех
                филиалах.
              </p>
              {affiliatesData?.length > 0 && (
                <>
                  <Form.Check className="mb-3">
                    <Form.Check.Input
                      type="checkbox"
                      checked={
                        affiliatesData?.length > 0 &&
                        affiliatesData?.length === data?.relationBlogs?.length
                      }
                      id="affiliate_all"
                      onChange={() => {
                        if (
                          affiliatesData?.length > 0 &&
                          affiliatesData?.length === data?.relationBlogs?.length
                        ) {
                          setValue("relationBlogs", []);
                        } else {
                          setValue(
                            "relationBlogs",
                            affiliatesData.map((e) => ({
                              affiliateId: e.id,
                            }))
                          );
                        }
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="affiliate_all"
                      className="ms-2 fs-09"
                    >
                      Выделить все
                    </Form.Check.Label>
                  </Form.Check>
                  <hr />
                </>
              )}
              {affiliatesData?.length > 0 &&
                affiliatesData.map((e) => {
                  let option =
                    data?.relationBlogs?.length > 0 &&
                    data.relationBlogs.find(
                      (item) => item.affiliateId === e.id
                    );

                  return (
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Form.Check>
                        <Form.Check.Input
                          type="checkbox"
                          name="relationBlogs"
                          id={"affiliate_" + e.id}
                          checked={!!option}
                          onChange={() => {
                            if (!!option) {
                              let newArray = data.relationBlogs.filter(
                                (item) => item.affiliateId != e.id
                              );
                              setValue("relationBlogs", newArray);
                            } else {
                              let newArray =
                                data?.relationBlogs?.length > 0
                                  ? [
                                      ...data.relationBlogs,
                                      {
                                        affiliateId: e.id,
                                      },
                                    ]
                                  : [
                                      {
                                        affiliateId: e.id,
                                      },
                                    ];
                              setValue("relationBlogs", newArray);
                            }
                          }}
                        />
                        <Form.Check.Label
                          htmlFor={"affiliate_" + e.id}
                          className="ms-2 fs-09"
                        >
                          {e?.title ? e.title : e.full}
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  );
                })}
            </Tab.Pane>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

export default BlogEdit;
