import moment from "moment";
import React, { memo, useCallback, useLayoutEffect, useState } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { moduleRatePrice } from "../../helpers/module";
import {
  createModule,
  deleteModule,
  editModule,
  getModule,
  resetModule,
  statusModule,
} from "../../services/module";
import FormModule from "./forms";

const ModuleEdit = memo(() => {
  const { moduleId } = useParams();
  const brand = useSelector((state) => state.brand.active);
  const affiliate = useSelector((state) => state.affiliate.active);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalReset, setModalReset] = useState(false);
  const [initalData, setInitalData] = useState(false);
  const [openDesciption, setOpenDesciption] = useState(false);

  const [form, setForm] = useState({
    loading: true,
    data: false,
  });

  let price = moduleRatePrice({
    module: form?.data,
    relationModule: form?.data?.relationModule,
    brand,
  });

  const getData = useCallback(() => {
    getModule({ moduleId, affiliateId: affiliate?.id ?? null })
      .then(
        (res) =>
          res &&
          setForm({
            loading: false,
            data: res,
          })
      )
      .catch(() =>
        setForm((res) => ({
          ...res,
          loading: false,
        }))
      );
  }, [moduleId, affiliate, form]);

  useLayoutEffect(() => {
    setForm((prev) => ({ ...prev, loading: true }));
    getData();
  }, [moduleId, brand, affiliate]);

  const onUpdate = useCallback(() => {
    getData();
  }, [form]);

  const onSubmit = useCallback((data) => {
    delete data?.relationModule?.log;

    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }

    editModule(formData)
      .then(() => {
        setForm({
          loading: false,
          data,
        });
        NotificationManager.success("Модуль успешно обновлен");
      })
      .catch(
        (err) =>
          err && NotificationManager.error("Ошибка при сохранении модуля")
      );
  }, []);

  const onCreate = useCallback(() => {
    createModule({ ...form.data, affiliateId: affiliate?.id ?? null })
      .then((res) => {
        NotificationManager.success("Модуль успешно подключен");
        setForm({
          loading: false,
          data: res,
        });
      })
      .catch(
        (err) =>
          err && NotificationManager.error("Ошибка при подключении модуля")
      );
  }, [form.data, affiliate]);

  const onStatus = useCallback(() => {
    setModalStatus(false);
    statusModule({ ...form.data, affiliateId: affiliate?.id ?? null })
      .then(() => {
        NotificationManager.success(
          form.data.relationModule.status === 0
            ? "Модуль успешно включен"
            : "Модуль успешно отключен"
        );
        setForm({
          loading: false,
          data: {
            ...form.data,
            relationModule: {
              ...form.data.relationModule,
              status: form.data.relationModule.status === 0 ? 1 : 0,
            },
          },
        });
      })
      .catch(
        (err) =>
          err && NotificationManager.error("Ошибка при отключении модуля")
      );
  }, [form.data, affiliate]);

  const onDelete = useCallback(() => {
    setModalDelete(false);
    deleteModule({ ...form.data, affiliateId: affiliate?.id ?? null })
      .then(() => {
        NotificationManager.success("Модуль успешно удален");
        onUpdate();
      })
      .catch(
        (err) => err && NotificationManager.error("Ошибка при удалении модуля")
      );
  }, [form.data, affiliate]);

  const onReset = useCallback(() => {
    setModalReset(false);
    resetModule({
      ...form.data,
      options: initalData,
      affiliateId: affiliate?.id ?? null,
    })
      .then(() => {
        NotificationManager.success("Модуль успешно очищен");
        setForm({
          loading: false,
          data: { ...form.data, options: initalData },
        });
        onUpdate();
      })
      .catch(
        (err) => err && NotificationManager.error("Ошибка при очистке модуля")
      );
  }, [form.data, initalData, affiliate]);

  if (form.loading) {
    return <Loader full />;
  }

  if (!form?.data?.id) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такого модуля нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title={form.data?.title ?? "Модуль"} />
      <Row className="d-flex justify-content-center">
        {form.data?.relationModule?.end && (
          <Col xxl={9} xl={8}>
            <FormModule
              onSubmit={onSubmit}
              onUpdate={onUpdate}
              setInitalData={setInitalData}
              data={form.data}
            />
          </Col>
        )}
        <Col xxl={3} xl={4}>
          <div className="position-sticky top-h">
            <Card body className="mb-4">
              <Row>
                <Col
                  md="12"
                  sm="12"
                  xs="12"
                  className="d-flex justify-content-center"
                >
                  <img
                    width={80}
                    height={80}
                    className="module-image mb-3"
                    src={
                      form.data?.media
                        ? "/images/modules/" + form.data.media
                        : "/images/empty-product-image.png"
                    }
                  />
                </Col>
                <Col>
                  {affiliate && affiliate?.id && (
                    <div className="mb-2 d badge text-wrap bg-success">
                      {affiliate.full}
                    </div>
                  )}
                  <h5 className="mb-2 text-center">
                    {form.data?.title ?? "Модуль"}
                  </h5>
                  <p
                    className={
                      "fs-08 text-gray text-center module-description" +
                      (openDesciption ? " show" : "")
                    }
                  >
                    {form.data?.description
                      ? form.data.description.trim()
                      : "Описание отсутствует"}
                  </p>
                  {form.data?.description?.length > 80 && (
                    <p className="fs-08 mt-1 text-center">
                      <a
                        className="text-success"
                        onClick={() => setOpenDesciption(!openDesciption)}
                      >
                        {openDesciption ? "Скрыть все" : "Показать все"}
                      </a>
                    </p>
                  )}
                </Col>
              </Row>
            </Card>
            <Card className="mb-4 p-3">
              {price?.status ? (
                <>
                  <h5 className="mb-2 d-flex align-items-center justify-content-between">
                    <div className="fs-09">Информация</div>
                    <div className="fs-08">
                      {form.data.relationModule?.status === 1 ? (
                        <Badge className="ms-2" bg="success">
                          {form.type == "one" ? "Выполнено" : "Активно"}
                        </Badge>
                      ) : (
                        <Badge className="ms-2" bg="danger">
                          Отключено
                        </Badge>
                      )}
                    </div>
                  </h5>
                  <p className="text-muted fs-08 mb-1">
                    Подключение:{" "}
                    <span className="text-dark">
                      {moment(form.data.relationModule.start).calendar()}
                    </span>
                  </p>
                  <p className="text-muted fs-08">
                    Завершение:{" "}
                    <span className="text-dark">
                      {moment(form.data.relationModule.end).calendar()}
                    </span>
                  </p>
                  {form.data.relationModule?.status === 0 ? (
                    <Button
                      className="btn btn-sm btn-primary w-100 mt-3"
                      onClick={() => setModalStatus(!modalStatus)}
                    >
                      Включить
                    </Button>
                  ) : (
                    <Row className="gx-2">
                      <Col md={12}>
                        <Button
                          className="btn btn-sm btn-danger w-100 mt-3"
                          onClick={() => setModalStatus(!modalStatus)}
                        >
                          Отключить
                        </Button>
                      </Col>
                      <Col md={12}>
                        <Button
                          className="btn btn-sm btn-light w-100 mt-3"
                          onClick={() => setModalReset(!modalReset)}
                        >
                          Очистить данные
                        </Button>
                      </Col>
                      <Col md={12}>
                        <Button
                          className="btn btn-sm btn-dark w-100 mt-3"
                          onClick={() => setModalDelete(!modalDelete)}
                        >
                          Удалить
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <CustomModal
                    title="Подтверждение"
                    show={modalStatus}
                    setShow={setModalStatus}
                    footer={
                      <>
                        <Button
                          className="me-3"
                          onClick={() => setModalStatus(!modalStatus)}
                        >
                          Отмена
                        </Button>
                        {form.data.relationModule?.status === 0 ? (
                          <Button className="btn-primary" onClick={onStatus}>
                            Включить
                          </Button>
                        ) : (
                          <Button className="btn-danger" onClick={onStatus}>
                            Отключить
                          </Button>
                        )}
                      </>
                    }
                  >
                    Вы подтверждайте данное действие?
                  </CustomModal>
                  <CustomModal
                    title="Подтверждение удаления"
                    show={modalDelete}
                    setShow={setModalDelete}
                    footer={
                      <>
                        <Button
                          className="me-3"
                          onClick={() => setModalDelete(!modalDelete)}
                        >
                          Отмена
                        </Button>
                        <Button className="btn-danger" onClick={onDelete}>
                          Удалить
                        </Button>
                      </>
                    }
                  >
                    Вы подтверждайте удаление модуля? Все данные будут удалены
                    связанные с этим модулем, восстановить данные будет
                    невозможно.
                  </CustomModal>
                  <CustomModal
                    title="Очистика данных"
                    show={modalReset}
                    setShow={setModalReset}
                    footer={
                      <>
                        <Button
                          className="me-3"
                          onClick={() => setModalReset(!modalReset)}
                        >
                          Отмена
                        </Button>

                        <Button className="btn-danger" onClick={onReset}>
                          Очистить данные
                        </Button>
                      </>
                    }
                  >
                    Вы подтверждайте очистку данных модуля? Все данные будут
                    удалены связанные с этим модулем, восстановить данные будет
                    невозможно.
                  </CustomModal>
                </>
              ) : (
                <>
                  {form.data?.new && (
                    <div className="mb-3">
                      <Badge bg="danger">Новинка</Badge>
                    </div>
                  )}
                  <div className="mb-3">
                    <h3 className="fw-7 mb-0">{price?.value}</h3>
                  </div>
                  <Button className="btn btn-primary w-100" onClick={onCreate}>
                    Подключить
                  </Button>
                </>
              )}
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
});

export default ModuleEdit;
