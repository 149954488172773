import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  active: false,
};

const affiliateSlice = createSlice({
  name: "affiliate",
  initialState,
  reducers: {
    setAffiliates: (state, action) => {
      state.data = action.payload.data ?? [];
      state.active = action.payload.active ?? state.active;
    },
    mainAffiliateEdit: (state, action) => {
      let data = state.data.map((e) => {
        e.main = e.id === action?.payload;
        return e;
      });

      state.data = data;
      state.active = data.find((e) => e.main);
    },
    resetAffiliate: (state, action) => {
      return { ...initialState };
    },
  },
});

export const { setAffiliates, mainAffiliateEdit, resetAffiliate } = affiliateSlice.actions;

export default affiliateSlice.reducer;
