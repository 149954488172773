import moment from "moment";
import Howler from "howler";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Container, Dropdown, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { updateNotification } from "../store/reducers/notificationSlice";
import { createSelector } from "reselect";
// import useIsMobile from "../hooks/isMobile";
// import MobileNav from "./MobileNav";
import { audioList, getLang, languageCode, localeData } from "../helpers/all";
import { logout } from "../services/auth";
import { mainAffiliateEdit } from "../store/reducers/affiliateSlice";
import { mainBrandEdit } from "../store/reducers/brandSlice";
import Button from "./UI/Button";
import Select from "./UI/Select";
import CustomModal from "./utils/CustomModal";
import { useTranslation } from "react-i18next";
import { editMemberLang } from "../services/member";
import { NotificationManager } from "react-notifications";
import { setUser } from "../store/reducers/authSlice";
import {
  IoMenu,
  IoVolumeHighOutline,
  IoVolumeMuteOutline,
} from "react-icons/io5";
import AdminMenu from "./Menu";
import MobileNav from "./MobileNav";
import socket from "../config/socket";

const Date = memo(() => {
  const [date, setDate] = useState(moment().format("DD MMM YYYY HH:mm:ss"));

  const updateTime = () => setDate(moment().format("DD MMM YYYY HH:mm:ss"));

  useEffect(() => {
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  return date;
});

const Header = memo(() => {
  const { t, i18n } = useTranslation();
  // const isMobile = useIsMobile();
  const [audio, setAudio] = useState(false);
  const brand = useSelector((state) => state.brand);
  const brandActive = useSelector((state) => state.brand?.active);
  const affiliate = useSelector((state) => state.affiliate);
  // Селектор для получения данных филиалов
  const selectAffiliateData = (state) => state.affiliate.data;

  // Мемоизированный селектор для получения ID филиалов
  const selectAffiliateIds = createSelector([selectAffiliateData], (data) =>
    data.map((e) => e.id).filter((e) => e)
  );

  // Использование в компоненте
  const affiliateIds = useSelector(selectAffiliateIds);
  const user = useSelector((state) => state.auth.user);

  const [isShowBurgerMenu, setShowBurgerMenu] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeBurgerMenu = useCallback(() => setShowBurgerMenu(false), []);
  const [showLogout, setShowLogout] = useState(false);

  const onAffiliateMain = useCallback((data) => {
    dispatch(mainAffiliateEdit(data.value));
  }, []);

  const onBrandMain = useCallback((data) => {
    dispatch(mainBrandEdit(data.value));
  }, []);

  const editLang = useCallback(
    (lang) => {
      lang = languageCode(lang.trim());
      editMemberLang(lang).then(() => {
        dispatch(setUser({ ...user, lang }));
        NotificationManager.success("Язык изменен");
      });
      i18n.changeLanguage(lang);
      moment.locale(lang);
    },
    [i18n, user]
  );

  useEffect(() => {
    if (user?.id && brand?.active?.id) {
      socket.on("notifications/brand/" + brand.active.id, (data) => {
        if (data) {
          dispatch(updateNotification(data));
          if (
            audio &&
            !audio?.playing() &&
            data?.order &&
            (affiliateIds.includes(Number(data?.order?.affiliateId)) ||
              (Number(affiliate?.active?.id) > 0 &&
                Number(affiliate?.active.id) ===
                  Number(data?.order?.affiliateId))) &&
            user?.options?.audio > 0
          ) {
            audio.play();
          }
        }
      });

      return () => {
        socket.off("notifications/brand/" + brand.active.id);
      };
    }
  }, [user, brand.active, audio, affiliateIds, affiliate?.active]);

  useEffect(() => {
    if (user?.id && brand?.active?.id) {
      const handleClick = () => {
        if (user?.options?.audio > 0) {
          if (!audio) {
            const newAudio = new Howler.Howl({
              src: [audioList.find((e) => e.id === user.options.audio).src],
              loop: !!user.options.audioLoop,
              volume: 1,
            });
            setAudio(newAudio);
          } else if (audio && audio?.playing()) {
            audio.stop();
          }
        }
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }
  }, [user, brand.active, audio]);

  return (
    <>
      <header>
        <Container fluid className="h-100">
          <Navbar collapseOnSelect expand="lg">
            <Container fluid="xl" className="p-0">
              <a
                className="d-lg-none"
                onClick={() => setShowBurgerMenu(!isShowBurgerMenu)}
              >
                <IoMenu size={25} />
              </a>
              <Navbar.Toggle aria-controls="navbar-dark-example">
                <img src="/images/avatar.png" height={35} />
              </Navbar.Toggle>
              <Navbar.Collapse id="navbar-dark-example">
                <Nav className="me-auto my-2 ps-4 my-lg-0 d-flex align-items-center">
                  {brand?.data?.length > 1 ? (
                    <Select
                      label={t("Бренд")}
                      placeholder={t("Выберите бренд")}
                      className="me-lg-3 mb-lg-0 mb-3"
                      onClick={(e) => onBrandMain(e)}
                      value={brand.active.id}
                      data={brand.data.map((e) => ({
                        title: e.title,
                        value: e.id,
                        main: e.main,
                      }))}
                    />
                  ) : (
                    <div className="d-flex align-items-center flex-row">
                      <img
                        src="/images/brand.png"
                        height={35}
                        className="me-2"
                      />
                      <div className="me-4 fw-6">{brand.active.title}</div>
                    </div>
                  )}
                  {affiliate?.data?.length > 1 && (
                    <Select
                      label={t("Филиал")}
                      search={affiliate.data?.length > 2}
                      placeholder={t("Выберите филиал")}
                      onClick={(e) => onAffiliateMain(e)}
                      value={affiliate.active?.id ?? null}
                      data={affiliate.data.map((e) => ({
                        title: e.title ? e.title : e.full,
                        subTitle: e.title != e.full && e.full ? e.full : null,
                        value: e.id,
                        main: e.main,
                      }))}
                    />
                  )}
                  {affiliate?.data?.length === 1 && (
                    <div className="d-flex align-items-center flex-row">
                      <img
                        src="/images/affiliate.png"
                        height={35}
                        className="me-2"
                      />
                      <div>
                        {affiliate.data[0]?.title && (
                          <b>{affiliate.data[0].title}</b>
                        )}
                        {affiliate.data[0].title != affiliate.data[0].full &&
                          affiliate.data[0].full && (
                            <p
                              className={
                                affiliate.data[0]?.title
                                  ? "mb-0 fs-08 text-muted"
                                  : "mb-0"
                              }
                            >
                              {affiliate.data[0].full}
                            </p>
                          )}
                      </div>
                    </div>
                  )}
                </Nav>
                <Nav className="d-flex">
                  {!audio ? (
                    <OverlayTrigger
                      placement="bottom"
                      className="zindex"
                      overlay={
                        <Tooltip className="zindex fs-09" id="audio">
                          Звуковые уведомления выключены. Нажмите на любую
                          область для включения звука.
                        </Tooltip>
                      }
                    >
                      <Link className="nav-link" to="/account">
                        <IoVolumeMuteOutline
                          size={22}
                          className="text-danger"
                        />
                      </Link>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement="bottom"
                      className="zindex"
                      overlay={
                        <Tooltip className="zindex fs-09" id="audio">
                          Звуковые уведомления включены
                        </Tooltip>
                      }
                    >
                      <Link className="nav-link" to="/account">
                        <IoVolumeHighOutline
                          size={22}
                          className="text-success"
                        />
                      </Link>
                    </OverlayTrigger>
                  )}

                  <Nav.Link className="nav-date">
                    <Date />
                  </Nav.Link>
                  <div className="d-flex flex-column d-lg-none">
                    <p className="fw-7 fs-09 p-2">
                      {t(user.firstName)} {t(user.lastName)}
                    </p>
                    <p className="text-muted fs-09 p-2">ID {user.id}</p>
                    <Dropdown.Divider className="dashed" />
                    <Link className="p-2" as={Link} to="/account">
                      {t("Профиль")}
                    </Link>
                    <Link className="p-2" as={Link} to="/payment">
                      {t("Оплата")}
                    </Link>
                    <Dropdown.Divider className="dashed" />
                    <Link
                      className="p-2 mb-3"
                      onClick={() => setShowLogout(!showLogout)}
                    >
                      {t("Выход")}
                    </Link>
                    {brandActive?.options?.lang?.length > 0 &&
                      brandActive.options.lang.map((e) => {
                        let item = localeData.find(
                          (d) => d.lang === languageCode(e)
                        );
                        return (
                          <Link
                            onClick={() => editLang(item.lang)}
                            className="d-flex align-items-center flex-row fs-09 p-2"
                          >
                            <img
                              src={item.image}
                              height={20}
                              className="me-2"
                            />
                            {t(item.title)}
                          </Link>
                        );
                      })}
                  </div>
                  <Dropdown className="d-flex d-none d-lg-flex align-items-center">
                    <Dropdown.Toggle
                      as={React.forwardRef(({ children, onClick }, ref) => (
                        <Nav.Link
                          ref={ref}
                          className="py-0"
                          onClick={(e) => {
                            e.preventDefault();
                            onClick(e);
                          }}
                        >
                          <img
                            src={
                              user?.lang
                                ? getLang(user.lang)?.image
                                : getLang("ru")?.image
                            }
                            height={25}
                            className="language"
                          />
                        </Nav.Link>
                      ))}
                    />
                    <Dropdown.Menu align="end">
                      {brandActive?.options?.lang?.length > 0 ? (
                        brandActive.options.lang.map((e) => {
                          let item = localeData.find(
                            (d) => d.lang === languageCode(e)
                          );
                          return (
                            <Dropdown.Item
                              onClick={() => editLang(item.lang)}
                              className="d-flex align-items-center flex-row fs-09"
                            >
                              <img
                                src={item.image}
                                height={20}
                                className="me-2"
                              />
                              {t(item.title)}
                            </Dropdown.Item>
                          );
                        })
                      ) : (
                        <Dropdown.Item
                          onClick={() => editLang("ru")}
                          className="d-flex align-items-center flex-row fs-09"
                        >
                          <img
                            src={require("../assets/images/country/russia.png")}
                            height={20}
                            className="me-2"
                          />
                          {t("Русский")}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <Dropdown>
                    <Dropdown.Toggle
                      as={React.forwardRef(({ children, onClick }, ref) => (
                        <Nav.Link
                          ref={ref}
                          onClick={(e) => {
                            e.preventDefault();
                            onClick(e);
                          }}
                        >
                          <IoNotificationsOutline size={24} />
                          {user?.notificationCount > 0 && (
                            <Badge pill className="ms-2" bg="danger">
                              {user.notificationCount}
                            </Badge>
                          )}
                        </Nav.Link>
                      ))}
                    />
                    <Dropdown.Menu align="end">
                      <div className="notification-menu">
                        <p className="fw-7 fs-09">Уведомления</p>
                        <p className="text-muted fs-09">
                          У вас нет новых уведомлений
                        </p>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <Dropdown className="d-flex d-none d-lg-flex align-items-center">
                    <Dropdown.Toggle
                      as={React.forwardRef(({ children, onClick }, ref) => (
                        <Nav.Link
                          ref={ref}
                          className="py-0"
                          onClick={(e) => {
                            e.preventDefault();
                            onClick(e);
                          }}
                        >
                          <img src="/images/avatar.png" height={35} />
                        </Nav.Link>
                      ))}
                    />
                    <Dropdown.Menu align="end">
                      <div className="account-menu">
                        <p className="fw-7 fs-09">
                          {t(user.firstName)} {t(user.lastName)}
                        </p>
                        <p className="text-muted fs-09">ID {user.id}</p>
                      </div>
                      <Dropdown.Divider className="dashed" />
                      <Dropdown.Item as={Link} to="/account">
                        {t("Профиль")}
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/payment">
                        {t("Оплата")}
                      </Dropdown.Item>
                      <Dropdown.Divider className="dashed" />
                      <Dropdown.Item onClick={() => setShowLogout(!showLogout)}>
                        {t("Выход")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
      </header>

      <MobileNav />

      <Offcanvas show={isShowBurgerMenu} onHide={closeBurgerMenu}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fw-7">Меню</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AdminMenu close={() => closeBurgerMenu()} />
        </Offcanvas.Body>
      </Offcanvas>
      <CustomModal
        show={showLogout}
        setShow={setShowLogout}
        title={t("Подтвердите действие")}
        footer={
          <>
            <Button
              type="button"
              className="btn-default"
              onClick={() => setShowLogout(!showLogout)}
            >
              {t("Отмена")}
            </Button>
            <Button
              type="button"
              className="btn-primary"
              onClick={() => dispatch(logout(navigate))}
            >
              {t("Выйти")}
            </Button>
          </>
        }
      >
        {t("Вы точно хотите выйти?")}
      </CustomModal>
    </>
  );
});

export default Header;
