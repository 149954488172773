import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Col, Collapse, Dropdown, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline, IoTrashOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import PreviewImages from "../../components/PreviewImages";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { currencyData, timezones, work } from "../../helpers/all";
import useDebounce from "../../hooks/useDebounce";
import { editAffiliate, getAffiliate } from "../../services/affiliate";
import { getDadataStreets } from "../../services/dadata";
import { getModule } from "../../services/module";
import { setAffiliates } from "../../store/reducers/affiliateSlice";

const EditAffiliate = () => {
  const { affiliateId } = useParams();
  const brand = useSelector((state) => state.brand.active);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [streets, setStreets] = useState([]);
  const [cities, setCities] = useState([]);
  const [module, setModule] = useState({
    terminals: [],
    discounts: [],
    loading: true,
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(null);
  const initialFull = useRef(null);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      apiId: "",
      cities: [],
      organizationId: "",
      title: null,
      desc: null,
      full: "",
      media: null,
      phone: null,
      email: null,
      options: {
        interval: false,
        timezone: "Europe/Moscow",
        currency: "RUB",
        area: null,
        city: "",
        flat: "",
        work: work,
        block: "",
        house: "",
        okato: "",
        oktmo: "",
        region: "",
        street: "",
        country: "",
        fias_id: "",
        kladr_id: "",
        coordinates: {
          lat: "",
          lon: "",
        },
        postal_code: "",
        preorderMax: 7,
        preorderMin: 60,
        area_fias_id: null,
        area_kladr_id: null,
        house_fias_id: "",
        discountPickup: "",
        house_kladr_id: null,
        region_fias_id: "",
        region_kladr_id: "",
        settlement_fias_id: null,
        settlement_kladr_id: null,
      },
      comment: null,
      status: 1,
    },
  });

  const data = useWatch({ control });

  const streetText = useDebounce(data.full, 1000);

  const clickAffiliate = (affiliate) => {
    setValue("full", affiliate.value ?? null);

    setValue("options.country", affiliate.data.country ?? null);

    setValue("options.region", affiliate.data.region ?? null);

    setValue("options.city", affiliate.data.city ?? null);
    setValue("options.settlement", affiliate.data.settlement ?? null);
    setValue("options.area", affiliate.data.area ?? null);
    setValue("options.street", affiliate.data.street ?? null);
    setValue("options.house", affiliate.data.house ?? null);

    setValue("options.coordinates.lat", affiliate.data.geo_lat ?? null);
    setValue("options.coordinates.lon", affiliate.data.geo_lon ?? null);

    setValue("options.fias_id", affiliate.data.fias_id ?? null);
    setValue("options.region_fias_id", affiliate.data.region_fias_id ?? null);
    setValue("options.area_fias_id", affiliate.data.area_fias_id ?? null);
    setValue("options.house_fias_id", affiliate.data.house_fias_id ?? null);
    setValue(
      "options.settlement_fias_id",
      affiliate.data.settlement_fias_id ?? null
    );

    setValue("options.kladr_id", affiliate.data.kladr_id ?? null);
    setValue("options.region_kladr_id", affiliate.data.region_kladr_id ?? null);
    setValue("options.area_kladr_id", affiliate.data.area_kladr_id ?? null);
    setValue("options.house_kladr_id", affiliate.data.area_kladr_id ?? null);
    setValue(
      "options.settlement_kladr_id",
      affiliate.data.settlement_kladr_id ?? null
    );

    setValue("options.postal_code", affiliate.data.postal_code ?? null);

    setValue("options.okato", affiliate.data.okato ?? null);
    setValue("options.oktmo", affiliate.data.oktmo ?? null);

    setShowDropdown(false);
  };

  const onKeyDown = (e) => {
    if (e === "Enter" && streets?.length > 0) {
      clickAffiliate(streets[0]);
      setStreets([]);
    }
  };

  useEffect(() => {
    if (initialFull.current === null || !initialFull.current) {
      initialFull.current = data.full;
    } else if (streetText && data.full !== initialFull.current) {
      getDadataStreets(streetText).then((res) => {
        if (res?.data?.suggestions) {
          setStreets(res.data.suggestions);
        }
      });
    }
  }, [streetText, initialFull.current, data.full]);

  useLayoutEffect(() => {
    getAffiliate(affiliateId)
      .then((res) => {
        if (res?.affiliate) {
          reset(res.affiliate);
          setTitle({
            title: res?.affiliate?.title,
            full: res?.affiliate?.full,
          });
        }

        if (res?.cities?.length > 0) {
          let citiesData = res.cities.filter(
            (e) =>
              e.relationCities[0] &&
              e.relationCities[0].affiliateId === Number(affiliateId)
          );

          setValue("cities", citiesData);
          setCities(
            res.cities
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((e) => ({ title: e.title, value: e.id }))
          );
        }
        if (res?.categories?.length > 0) {
          setValue(
            "categories",
            res.categories
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((e) => ({ title: e.title, value: e.id }))
          );
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
    getModule({ moduleId: 1 })
      .then((res) => {
        let terminals = [];
        let discounts = [];
        if (res.relationModule?.options?.terminals?.length > 0) {
          res.relationModule.options.terminals.forEach(
            (e) =>
              e?.items?.length > 0 &&
              e.items.forEach((e2) =>
                terminals.push({ title: e2.name, value: e2.id })
              )
          );
        }
        if (res.relationModule?.options?.discounts?.length > 0) {
          res.relationModule.options.discounts.forEach((e) =>
            discounts.push({ title: e.name, value: e.id, percent: e.percent })
          );
        }

        setModule({
          loading: false,
          terminals,
          discounts,
        });
      })
      .catch(() =>
        setModule((res) => ({
          ...res,
          loading: false,
        }))
      );
  }, [affiliateId]);

  const onSubmit = useCallback((data) => {
    if (data?.options?.preorderMin && data?.options?.preorderMin < 0) {
      return NotificationManager.error("Минимально 0 часов предзаказа");
    }
    if (data?.options?.preorderMax && data?.options?.preorderMax < 0) {
      return NotificationManager.error("Минимально 0 дней предзаказа");
    }

    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }

    editAffiliate(formData)
      .then((res) => {
        NotificationManager.success("Филал успешно изменен");
        if (res?.length > 0) {
          dispatch(setAffiliates(res));
        }
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  if (loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title={title?.title ?? title?.full ?? "Редактирование филиала"} />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/options/affiliates"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button onClick={handleSubmit(onSubmit)}>Сохранить изменения</Button>
      </div>
      <h3 className={!title?.full && "mb-4"}>
        {title?.title ?? "Редактирование филиала"}
      </h3>
      {title?.full && <p className="mb-4 text-muted">{title?.full}</p>}
      <Row>
        <Col md={6}>
          <Row>
            <Col>
              <div className="mb-4">
                <DragDropFile
                  col={6}
                  file={data.file}
                  onChange={(e) => setValue("file", e)}
                  onDelete={(e) => setValue("file", e.file)}
                />
              </div>
            </Col>
            {data?.media && (
              <Col>
                <PreviewImages
                  medias={data?.media}
                  type="affiliate"
                  col={12}
                  onDelete={(e) => {
                    setValue("media", e.media);
                    let newArrayDelete =
                      data?.delete?.length > 0
                        ? [...data.delete, e.delete]
                        : [e.delete];
                    setValue("delete", newArrayDelete);
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col md={6}>
          <Form.Check className="mb-4 d-block">
            <Form.Check.Input
              type="checkbox"
              name="status"
              id="status"
              defaultChecked={!!data?.status}
              {...register("status")}
            />
            <Form.Check.Label htmlFor="status" className="ms-2">
              Филиал работает
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="mb-4 d-block">
            <Form.Check.Input
              type="checkbox"
              name="main"
              id="main"
              value={1}
              defaultChecked={getValues("main")}
              {...register("main")}
            />
            <Form.Check.Label htmlFor="main" className="ms-2">
              Адрес по умолчанию
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="mb-4 d-block">
            <Form.Check.Input
              type="checkbox"
              name="options.integrationOffProduct"
              id="integration-off-product"
              value={true}
              defaultChecked={getValues("options.integrationOffProduct")}
              {...register("options.integrationOffProduct")}
            />
            <Form.Check.Label
              htmlFor="integration-off-product"
              className="ms-2"
            >
              Отключить обновление товаров из интеграции
            </Form.Check.Label>
          </Form.Check>
          <div className="mb-4">
            <Input
              errors={errors}
              label="Название"
              name="title"
              placeholder="Введите название (Необязательно)"
              register={register}
              validation={{
                maxLength: { value: 250, message: "Максимум 250 символов" },
              }}
            />
          </div>

          <div className="mb-4">
            <Textarea
              label="Описание"
              name="desc"
              placeholder="Введите описание (Необязательно)"
              errors={errors}
              rows={4}
              register={register}
              validation={{
                maxLength: { value: 5000, message: "Максимум 5000 символов" },
              }}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-4">
            <Textarea
              label="Юридическое лицо"
              name="options.organization"
              placeholder="Введите текст"
              errors={errors}
              rows={4}
              register={register}
              validation={{
                maxLength: { value: 500, message: "Максимум 500 символов" },
              }}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-4">
            <Textarea
              label="Условия доставки"
              name="options.delivery"
              placeholder="Введите текст"
              errors={errors}
              rows={4}
              register={register}
              validation={{
                maxLength: { value: 30000, message: "Максимум 30000 символов" },
              }}
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-2 position-relative">
            <Input
              errors={errors}
              label="Адрес"
              onKeyDown={(e) => onKeyDown(e)}
              onClick={() => setShowDropdown(true)}
              type="search"
              autoComplete="off"
              name="full"
              placeholder="Введите адрес"
              register={register}
              validation={{
                required: "Обязательное поле",
                maxLength: { value: 250, message: "Максимум 250 символов" },
              }}
            />
            {showDropdown && streets?.length > 0 && (
              <Dropdown.Menu
                onClick={() => setShowDropdown(false)}
                show
                className="w-100 custom-input-street"
              >
                {streets.map(
                  (item, key) =>
                    item && (
                      <Dropdown.Item
                        onClick={() => clickAffiliate(item)}
                        key={key}
                      >
                        {item.value}
                      </Dropdown.Item>
                    )
                )}
              </Dropdown.Menu>
            )}
          </div>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Select
            placeholder="Выберите часовой пояс"
            value={data.options.timezone}
            onClick={(e) => setValue("options.timezone", e.value)}
            data={timezones}
          />
        </Col>
      </Row>
      <a
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="d-inline-block mb-4 fs-09 text-muted"
      >
        Показать подробнее
      </a>

      <Collapse in={open}>
        <Row>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Страна"
                name="options.country"
                placeholder="Введите страну"
                register={register}
                validation={{
                  required: "Обязательное поле",
                  maxLength: { value: 150, message: "Максимум 150 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Регион"
                name="options.region"
                placeholder="Введите регион"
                register={register}
                validation={{
                  required: "Обязательное поле",
                  maxLength: { value: 150, message: "Максимум 150 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Город"
                name="options.city"
                placeholder="Введите город"
                register={register}
                validation={{
                  maxLength: { value: 150, message: "Максимум 150 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Улица"
                name="options.street"
                placeholder="Введите улицу"
                register={register}
                validation={{
                  maxLength: { value: 250, message: "Максимум 250 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Дом"
                name="options.house"
                placeholder="Введите дом"
                register={register}
                validation={{
                  maxLength: { value: 30, message: "Максимум 30 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Корпус"
                name="options.block"
                placeholder="Введите корпус"
                register={register}
                validation={{
                  maxLength: { value: 30, message: "Максимум 30 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Квартира"
                name="options.flat"
                placeholder="Введите квартиру"
                register={register}
                validation={{
                  maxLength: { value: 30, message: "Максимум 30 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Индекс"
                name="options.postal_code"
                placeholder="Введите индекс"
                register={register}
                validation={{
                  maxLength: { value: 30, message: "Максимум 30 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Широта"
                type="number"
                name="options.coordinates.lat"
                placeholder="Введите широту"
                register={register}
                validation={{
                  required: "Обязательное поле",
                  maxLength: { value: 50, message: "Максимум 50 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Долгота"
                type="number"
                name="options.coordinates.lon"
                placeholder="Введите долготу"
                register={register}
                validation={{
                  required: "Обязательное поле",
                  maxLength: { value: 50, message: "Максимум 50 символов" },
                }}
              />
            </div>
          </Col>
        </Row>
      </Collapse>

      <Row>
        <Col md={6}>
          <h5 className="mb-2">Номер телефона</h5>
          <div className="mb-4">
            <Input
              errors={errors}
              name="phone"
              placeholder="Введите номер телефона"
              register={register}
            />
          </div>
          <h5 className="mb-3">Скидка на самовывоз</h5>
          <div className="box box-gray mb-3">
            <div className="mb-3">
              <p className="text-muted fs-09 mb-2">Скидка из интеграции</p>
              <Select
                search
                value={data.options?.discountApiId}
                data={[{ title: "Нет", value: null }, ...module.discounts]}
                onClick={(e) => {
                  setValue("options.discountApiId", e.value);
                  if (Number(e?.percent) > 0) {
                    setValue("options.discountPickup", e.percent);
                  } else {
                    setValue("options.discountPickup", null);
                  }
                }}
              />
            </div>
            <div>
              <p className="text-muted fs-09 mb-2">Процент</p>
              <Input
                errors={errors}
                readOnly={!!!data.options?.discountApiId}
                name="options.discountPickup"
                placeholder="Укажите процент (Необязательно)"
                register={register}
              />
            </div>
            {data.options?.discountPickup && (
              <>
                <p className="text-muted fs-09 mb-2 mt-3">
                  Исключения по категории
                </p>
                <div className="body-scroll">
                  {data?.categories?.length > 0 &&
                    data.categories.map((category, index) => (
                      <Form.Check key={index} className="mb-3">
                        <Form.Check.Input
                          type="checkbox"
                          name="options.discountExceptions"
                          id={"discount-exceptions-" + category.value}
                          value={category.value}
                          defaultChecked={
                            data?.discountExceptions?.length > 0 &&
                            data.discountExceptions.findIndex(
                              (e) => e.id === category.value
                            ) != -1
                          }
                          {...register("options.discountExceptions")}
                        />
                        <Form.Check.Label
                          htmlFor={"discount-exceptions-" + category.value}
                          className="ms-2"
                        >
                          {category.title}
                        </Form.Check.Label>
                      </Form.Check>
                    ))}
                </div>
              </>
            )}
          </div>

          <h5 className="mb-2">Привязка к городам</h5>
          <p className="text-muted fs-09 mb-3">
            Выберите из списка города, либо{" "}
            <Link
              to="/options/affiliates/cities/create"
              className="text-success"
            >
              создайте город
            </Link>
          </p>
          {data?.cities?.length > 0 &&
            data.cities.map((item, index) => {
              return (
                <div className="mb-2 d-flex flex-row align-items-center">
                  <Select
                    classNameContainer="w-100"
                    value={item?.title ?? null}
                    onClick={(e) => {
                      let city = cities.find((city) => city.value === e.value);
                      if (data.cities.find((city) => city.value === e.value)) {
                        return NotificationManager.error(
                          "Данный город уже добавлен"
                        );
                      }
                      setValue(`cities.${index}`, {
                        id: city?.id ? city.id : city.value,
                        title: city?.title ? city.title : null,
                      });
                    }}
                    data={cities}
                  />
                  <Button
                    className="btn-light ms-2"
                    onClick={() =>
                      setValue(
                        "cities",
                        data.cities.filter((e) => e.value != item.value)
                      )
                    }
                  >
                    <IoTrashOutline size={20} />
                  </Button>
                </div>
              );
            })}
          <a
            className="text-success fs-09"
            onClick={() =>
              setValue(
                "cities",
                data?.cities?.length > 0
                  ? [...data.cities, { title: null, value: null }]
                  : [{ title: null, value: null }]
              )
            }
          >
            Добавить из списка
          </a>
        </Col>
        <Col md={6}>
          <h5 className="mb-2">Интеграция</h5>
          <p className="text-muted fs-09 mb-3">
            Укажите идентификатор филиала если вы используйте интеграцию
          </p>
          <div className="mb-4">
            {module?.terminals?.length > 0 ? (
              <Select
                value={data.apiId}
                onClick={(e) => setValue("apiId", e.value)}
                data={module.terminals}
              />
            ) : (
              <Input
                errors={errors}
                label="Идентификатор"
                name="apiId"
                placeholder="Идентификатор (Необязательно)"
                register={register}
              />
            )}
          </div>
          <h5 className="mb-3">Предзаказ</h5>
          <div className="mb-2">
            <Row>
              <Col>
                <Input
                  label="Минимально минут"
                  type="number"
                  min={0}
                  name="options.preorderMin"
                  register={register}
                />
                <p className="text-muted fs-08 mt-2 mb-3">
                  Минимальное время в минутах до предзаказа
                </p>
              </Col>
              <Col>
                <Input
                  label="Максимально дней"
                  type="number"
                  min={0}
                  name="options.preorderMax"
                  register={register}
                />
                <p className="text-muted fs-08 mt-2 mb-3">
                  Максимально дней предзаказа
                </p>
              </Col>
            </Row>
          </div>
          <h5 className="mb-2">Интервал</h5>
          <p className="text-muted fs-09 mb-3">
            Настройте выбор интервала для предзаказа
          </p>
          <div className="d-flex align-items-start flex-row flex-wrap mb-3">
            <Form.Check className="text-center align-items-center flex-column d-flex me-4">
              <Form.Check.Input
                id="interval-0"
                type="radio"
                name="options.interval"
                defaultChecked={!data?.options?.interval}
                value={false}
                {...register("options.interval")}
              />
              <Form.Label htmlFor="interval-0" className="fs-08 mt-1">
                Выкл
              </Form.Label>
            </Form.Check>
            <Form.Check className="text-center align-items-center flex-column d-flex me-4">
              <Form.Check.Input
                id="interval-1"
                type="radio"
                name="options.interval"
                value={15}
                {...register("options.interval")}
              />
              <Form.Label htmlFor="interval-1" className="fs-08 mt-1">
                15 мин
              </Form.Label>
            </Form.Check>
            <Form.Check className="text-center align-items-center flex-column d-flex me-4">
              <Form.Check.Input
                id="interval-2"
                type="radio"
                name="options.interval"
                value={30}
                {...register("options.interval")}
              />
              <Form.Label htmlFor="interval-2" className="fs-08 mt-1">
                30 мин
              </Form.Label>
            </Form.Check>
            <Form.Check className="text-center align-items-center flex-column d-flex me-4">
              <Form.Check.Input
                id="interval-3"
                type="radio"
                name="options.interval"
                value={60}
                {...register("options.interval")}
              />
              <Form.Label htmlFor="interval-3" className="fs-08 mt-1">
                1 час
              </Form.Label>
            </Form.Check>
            <Form.Check className="text-center align-items-center flex-column d-flex me-4">
              <Form.Check.Input
                id="interval-4"
                type="radio"
                name="options.interval"
                value={75}
                {...register("options.interval")}
              />
              <Form.Label htmlFor="interval-4" className="fs-08 mt-1">
                1 час
                <br />
                15 мин
              </Form.Label>
            </Form.Check>
            <Form.Check className="text-center align-items-center flex-column d-flex me-4">
              <Form.Check.Input
                id="interval-5"
                type="radio"
                name="options.interval"
                value={90}
                {...register("options.interval")}
              />
              <Form.Label htmlFor="interval-5" className="fs-08 mt-1">
                1 час
                <br />
                30 мин
              </Form.Label>
            </Form.Check>
            <Form.Check className="text-center align-items-center flex-column d-flex me-4">
              <Form.Check.Input
                id="interval-6"
                type="radio"
                name="options.interval"
                value={105}
                {...register("options.interval")}
              />
              <Form.Label htmlFor="interval-6" className="fs-08 mt-1">
                1 час
                <br />
                45 мин
              </Form.Label>
            </Form.Check>
            <Form.Check className="text-center align-items-center flex-column d-flex me-4">
              <Form.Check.Input
                id="interval-7"
                type="radio"
                name="options.interval"
                value={120}
                {...register("options.interval")}
              />
              <Form.Label htmlFor="interval-7" className="fs-08 mt-1">
                2 часа
              </Form.Label>
            </Form.Check>
            <Form.Check className="text-center align-items-center flex-column d-flex me-4">
              <Form.Check.Input
                id="interval-8"
                type="radio"
                name="options.interval"
                value={135}
                {...register("options.interval")}
              />
              <Form.Label htmlFor="interval-8" className="fs-08 mt-1">
                2 час
                <br />
                15 мин
              </Form.Label>
            </Form.Check>
            <Form.Check className="text-center align-items-center flex-column d-flex me-4">
              <Form.Check.Input
                id="interval-9"
                type="radio"
                name="options.interval"
                value={150}
                {...register("options.interval")}
              />
              <Form.Label htmlFor="interval-9" className="fs-08 mt-1">
                2 час
                <br />
                30 мин
              </Form.Label>
            </Form.Check>
            <Form.Check className="text-center align-items-center flex-column d-flex me-4">
              <Form.Check.Input
                id="interval-10"
                type="radio"
                name="options.interval"
                value={165}
                {...register("options.interval")}
              />
              <Form.Label htmlFor="interval-10" className="fs-08 mt-1">
                2 час
                <br />
                45 мин
              </Form.Label>
            </Form.Check>
            <Form.Check className="text-center align-items-center flex-column d-flex me-4">
              <Form.Check.Input
                id="interval-11"
                type="radio"
                name="options.interval"
                value={180}
                {...register("options.interval")}
              />
              <Form.Label htmlFor="interval-11" className="fs-08 mt-1">
                3 час
              </Form.Label>
            </Form.Check>
          </div>
          <h5 className="mb-3">Среднее время приготовления</h5>
          <div className="mb-4">
            <Input
              type="number"
              name="options.time"
              min={0}
              defaultValue={data?.options?.time ?? 0}
              errors={errors}
              register={register}
              validation={{
                required: "Обязательное поле",
                min: { value: 0, message: "Минимально 0 мин" },
                max: { value: 1000, message: "Минимально 1000 мин" },
              }}
            />
          </div>
          <h5 className="mb-3">ИНН</h5>
          <div className="mb-2">
            <Row>
              <Col>
                <Input name="options.inn" register={register} />
                <p className="text-muted fs-09 mt-2 mb-3">
                  Укажите инн если он нужен для оплаты онлайн
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h5 className="mb-2 mt-3">Время работы</h5>
          <p className="text-muted fs-09 mb-4">
            Если вы работайте в какой либо из дней круглосуточно, оставьте поле
            пустым
          </p>
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Все</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                onChange={(event) =>
                  setValue(
                    "options.work",
                    data.options?.work?.length > 0
                      ? data.options.work.map((e) => ({
                          ...e,
                          start: event,
                        }))
                      : work.map((e) => ({
                          ...e,
                          start: event,
                        }))
                  )
                }
              />
              <Input
                label="Конец"
                type="time"
                onChange={(event) =>
                  setValue(
                    "options.work",
                    data.options?.work?.length > 0
                      ? data.options.work.map((e) => ({
                          ...e,
                          end: event,
                        }))
                      : work.map((e) => ({
                          ...e,
                          end: event,
                        }))
                  )
                }
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-all"
                  checked={
                    data.options?.work &&
                    !!!data.options?.work.find((e) => !e.status)
                  }
                  onChange={(event) =>
                    setValue(
                      "options.work",
                      data.options?.work?.length > 0
                        ? data.options.work.map((e) => ({
                            ...e,
                            status: event.target.checked,
                          }))
                        : work.map((e) => ({
                            ...e,
                            status: event.target.checked,
                          }))
                    )
                  }
                />
                <Form.Check.Label htmlFor="status-all" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <hr />
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Пн -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.0.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.0.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-0"
                  {...register("options.work.0.status")}
                />
                <Form.Check.Label htmlFor="status-0" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Вт -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.1.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.1.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-1"
                  {...register("options.work.1.status")}
                />
                <Form.Check.Label htmlFor="status-1" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Ср -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.2.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.2.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-2"
                  {...register("options.work.2.status")}
                />
                <Form.Check.Label htmlFor="status-2" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Чт -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.3.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.3.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-3"
                  {...register("options.work.3.status")}
                />
                <Form.Check.Label htmlFor="status-3" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Пт -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.4.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.4.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-4"
                  {...register("options.work.4.status")}
                />
                <Form.Check.Label htmlFor="status-4" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Сб -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.5.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.5.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-5"
                  {...register("options.work.5.status")}
                />
                <Form.Check.Label htmlFor="status-5" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="mb-4 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Вс -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.6.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.6.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-6"
                  {...register("options.work.6.status")}
                />
                <Form.Check.Label htmlFor="status-6" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <h5 className="mb-2 mt-3">Залы</h5>
          <hr />
          {data?.halls?.length > 0 ? (
            data.halls.map((e) => (
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <span className="me-2 work-label">{e.title}</span>
                </div>
                <div>
                  <Form.Check className="mt-1 ms-2">
                    <Form.Check.Input
                      type="checkbox"
                      defaultChecked={e.status === 1}
                      id={"status-hall-" + e.id}
                      // {...register("options.work.0.status")}
                    />
                    <Form.Check.Label
                      htmlFor={"status-hall-" + e.id}
                      className="ms-2"
                    >
                      Работает
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              </div>
            ))
          ) : (
            <p className="text-muted fs-09 mb-4">
              Пока у вас не подключен ни один зал
            </p>
          )}

          <h5 className="mb-2 mt-3">Валюта</h5>
          <p className="text-muted fs-09 mb-4">
            С какой валютой работает данный филиал
          </p>
          {brand?.options?.currency?.length > 0 &&
            brand.options.currency.map((e) => {
              let item = currencyData.find(
                (c) => c.currency.toLowerCase() === e.toLowerCase()
              );
              return (
                <div>
                  <Form.Check className="my-2">
                    <Form.Check.Input
                      type="radio"
                      value={item.currency}
                      id={"currency-" + item.currency}
                      {...register("options.currency")}
                    />
                    <Form.Check.Label
                      htmlFor={"currency-" + item.currency}
                      className="ms-2"
                    >
                      {item.title} ({item.symbol})
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              );
            })}
        </Col>
      </Row>
    </>
  );
};

export default EditAffiliate;
