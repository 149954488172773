import React, { useCallback, useLayoutEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline, IoTrashOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import PreviewImages from "../../components/PreviewImages";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import {
  addCategoryParam,
  deleteCategoryParam,
  editCategory,
  getCategory,
} from "../../services/category";
import { useSelector } from "react-redux";
import { localeData, work } from "../../helpers/all";

const EditCategory = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [loading, setLoading] = useState(true);
  const brand = useSelector((state) => state.brand?.active);
  const [categories, setCategories] = useState([]);
  const [params, setParams] = useState([]);
  const [title, setTitle] = useState(null);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const data = useWatch({ control });

  const {
    control: controlParam,
    formState: { errors: errorsParam, isValid: isValidParam },
    register: registerParam,
    handleSubmit: handleSubmitParam,
    setValue: setValueParam,
    reset: resetParam,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      type: null,
      status: 1,
      options: { work: work },
    },
  });

  const dataParam = useWatch({ control: controlParam });

  const getPage = useCallback(() => {
    getCategory(categoryId)
      .then((res) => {
        reset({ ...data, ...res });
        setTitle(res?.title);
        resetParam({ ...dataParam, categoryId: res.id });
        res.categories =
          res?.categories?.length > 0
            ? res.categories.filter((e) => e.id !== res.id)
            : [];

        setCategories(res.categories);
        setParams(res.params);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [categoryId, data]);

  useLayoutEffect(() => {
    getPage();
  }, []);

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }

    editCategory(formData)
      .then(() => {
        NotificationManager.success("Каталог успешно обновлен");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  const onAddParam = useCallback((data) => {
    addCategoryParam(data)
      .then(() => {
        NotificationManager.success("Параметр успешно добавлен");
        resetParam();
        getPage();
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  const onDeleteParam = useCallback((id) => {
    deleteCategoryParam(id)
      .then(() => {
        NotificationManager.success("Параметр успешно удален");
        getPage();
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такой категории нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title={title ?? "Категории"} />
      <div>
        <Link
          to="/catalog/categories"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>{title ?? "Редактирование категории"}</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Tabs defaultActiveKey={0} className="mb-3" fill>
            <Tab eventKey={0} title="Основное">
              <Row>
                <Col>
                  <DragDropFile
                    maxAspect={1}
                    col={12}
                    file={data.file}
                    onChange={(e) => setValue("file", e)}
                    onDelete={(e) => setValue("file", e.file)}
                    sizeText="Рекомендовано: 500 х 500 px - Пропорции 1/1"
                  />
                  {data?.media && (
                    <PreviewImages
                      medias={data?.media}
                      type="category"
                      col={12}
                      onDelete={(e) => {
                        setValue("media", e.media);
                        let newArrayDelete =
                          data?.delete?.length > 0
                            ? [...data.delete, e.delete]
                            : [e.delete];
                        setValue("delete", newArrayDelete);
                      }}
                    />
                  )}
                </Col>
                <Col md={8}>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Input
                          defaultValue={data.title}
                          label="Название"
                          name="title"
                          errors={errors}
                          register={register}
                          validation={{
                            required: "Обязательное поле",
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Select
                          label="Тип"
                          value={data.type}
                          data={
                            brand.type == "food"
                              ? [
                                  { title: "Блюдо", value: "dish" },
                                  { title: "Добавка", value: "addition" },
                                  { title: "Модификатор", value: "modifier" },
                                  { title: "Дополнительно", value: "extra" },
                                  { title: "Подарок", value: "gift" },
                                  { title: "Акции", value: "sale" },
                                  { title: "Промокод", value: "promo" },
                                ]
                              : [
                                  { title: "Товар", value: "product" },
                                  { title: "Проект", value: "project" },
                                  { title: "Услуга", value: "service" },
                                  { title: "Подарок", value: "gift" },
                                  { title: "Акции", value: "sale" },
                                  { title: "Промокод", value: "promo" },
                                ]
                          }
                          onClick={(e) => setValue("type", e.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Input
                          defaultValue={data.priority ?? 0}
                          label="Порядок"
                          name="priority"
                          errors={errors}
                          register={register}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Select
                          label="Категория"
                          search
                          value={data.parent ?? ""}
                          data={[
                            { title: "Нет", value: "" },
                            ...(categories?.length > 0
                              ? categories.map((e) => ({
                                  title: e.title,
                                  value: e.id,
                                }))
                              : []),
                          ]}
                          onClick={(e) => setValue("parent", e.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <Form.Check className="my-2">
                        <Form.Check.Input
                          type="checkbox"
                          name="status"
                          id="status"
                          defaultChecked={!!data.status}
                          {...register("status")}
                        />
                        <Form.Check.Label htmlFor="status" className="ms-2">
                          Показать
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    {data?.type == "modifier" && (
                      <Col md={12}>
                        <Form.Check className="d-inline-block mb-4">
                          <Form.Check.Input
                            type="checkbox"
                            id="optional"
                            defaultChecked={!!data?.options?.optional}
                            {...register("options.optional")}
                          />
                          <Form.Check.Label htmlFor="optional" className="ms-2">
                            Необязательная категория модификаторов
                          </Form.Check.Label>
                        </Form.Check>
                      </Col>
                    )}
                    <Col md={12}>
                      <h5 className="mb-2 mt-1">Время работы</h5>
                      <p className="text-muted fs-09 mb-4">
                        Если категория работает в какой либо из дней
                        круглосуточно, оставьте поле пустым
                      </p>
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Все</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            onChange={(event) =>
                              setValue(
                                "options.work",
                                data.options?.work?.length > 0
                                  ? data.options.work.map((e) => ({
                                      ...e,
                                      start: event,
                                    }))
                                  : work.map((e) => ({
                                      ...e,
                                      start: event,
                                    }))
                              )
                            }
                          />
                          <Input
                            label="Конец"
                            type="time"
                            onChange={(event) =>
                              setValue(
                                "options.work",
                                data.options?.work?.length > 0
                                  ? data.options.work.map((e) => ({
                                      ...e,
                                      end: event,
                                    }))
                                  : work.map((e) => ({
                                      ...e,
                                      end: event,
                                    }))
                              )
                            }
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-all"
                              checked={
                                data.options?.work &&
                                !!!data.options?.work.find((e) => !e.status)
                              }
                              onChange={(event) =>
                                setValue(
                                  "options.work",
                                  data.options?.work?.length > 0
                                    ? data.options.work.map((e) => ({
                                        ...e,
                                        status: event.target.checked,
                                      }))
                                    : work.map((e) => ({
                                        ...e,
                                        status: event.target.checked,
                                      }))
                                )
                              }
                            />
                            <Form.Check.Label
                              htmlFor="status-all"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <hr />
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Пн -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.0.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.0.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-0"
                              {...register("options.work.0.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-0"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Вт -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.1.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.1.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-1"
                              {...register("options.work.1.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-1"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Ср -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.2.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.2.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-2"
                              {...register("options.work.2.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-2"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Чт -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.3.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.3.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-3"
                              {...register("options.work.3.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-3"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Пт -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.4.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.4.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-4"
                              {...register("options.work.4.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-4"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Сб -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.5.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.5.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-5"
                              {...register("options.work.5.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-5"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Вс -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.6.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.6.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-6"
                              {...register("options.work.6.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-6"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Tab>
            {(brand.type == "shop" || brand?.type == "service") && (
              <Tab eventKey={1} title="Параметры">
                {data?.relationParams?.length > 0 &&
                  data.relationParams.map((e) => (
                    <div className="p-2 d-flex flex-row justify-content-between align-items-center">
                      <div>{e.param.title}</div>
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <a className="me-3">
                          {e.status ? (
                            <Badge bg="success">Активно</Badge>
                          ) : (
                            <Badge bg="secondary">Архив</Badge>
                          )}
                        </a>
                        <a onClick={() => onDeleteParam(e.id)}>
                          <IoTrashOutline size={20} className="text-danger" />
                        </a>
                      </div>
                    </div>
                  ))}
                <div className="mt-3 px-2">
                  <p className="mb-4 text-muted">
                    Хотите добавить новые параметры?{" "}
                    <Link className="text-success" to="/catalog/params/create">
                      Создать параметр
                    </Link>
                  </p>
                  <Row>
                    <Col md={7}>
                      <div className="mb-3">
                        <Select
                          label="Параметры"
                          search
                          value={dataParam.id}
                          data={
                            params?.length > 0
                              ? params.map((e) => ({
                                  title: e.title,
                                  value: e.id,
                                }))
                              : []
                          }
                          onClick={(e) => setValueParam("id", e.value)}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <Form.Check className="my-2">
                        <Form.Check.Input
                          type="checkbox"
                          name="status"
                          id="status-child"
                          value={dataParam.status}
                          {...registerParam("status")}
                        />
                        <Form.Check.Label
                          htmlFor="status-child"
                          className="ms-2"
                        >
                          Показать
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col md={2}>
                      <Button
                        disabled={!isValidParam}
                        className="w-100"
                        onClick={handleSubmitParam(onAddParam)}
                      >
                        Добавить
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Tab>
            )}
            {brand.options?.lang?.length > 1 && (
              <Tab eventKey={1} title="Перевод">
                <Row>
                  {brand.options.lang
                    .filter((e) => e != "ru")
                    .map((lang) => (
                      <Col md={12}>
                        <div className="mb-3">
                          <Input
                            label={
                              localeData.find((e) => e.lang === lang)?.title
                            }
                            name={`options.${lang}.title`}
                            errors={errors}
                            register={register}
                          />
                        </div>
                      </Col>
                    ))}
                </Row>
              </Tab>
            )}
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditCategory;
