import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Card, Col, Dropdown, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  IoAdd,
  IoCloseOutline,
  IoCreateOutline,
  IoSaveOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { deleteUser, getUserDownload, getUsers } from "../../services/user";
import { Pagination } from "swiper/modules";
import moment from "moment";

const Users = () => {
  const { t } = useTranslation();
  const brand = useSelector((state) => state.brand.active);
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = createRef();
  const [users, setUsers] = useState({
    loading: true,
    items: [],
  });
  const [loadFile, setLoadFile] = useState(false);

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const [statistic, setStatistic] = useState({
    now: 0,
    activity: 0,
    new: 0,
    newProcent: 0,
  });

  const userColumns = [
    {
      name: "Имя",
      selector: "firstName",
      size: 3,
      cell: (row) => (
        <Link to={"/user/" + row.id} className="me-2">
          {row.firstName && row.firstName?.trim()?.length > 0
            ? row.firstName.trim()
            : "-"}
        </Link>
      ),
    },
    {
      name: "Номер телефона",
      selector: "phone",
      size: 2,
      cell: (row) => (
        <a href={"+" + row.phone} className="me-2">
          {row.phone ?? "-"}
        </a>
      ),
    },
    {
      name: "Email",
      selector: "email",
      cell: (row) => (
        <a href={"mailer:" + row.email} className="me-2">
          {row.email ?? "-"}
        </a>
      ),
    },
    // {
    //   width: "80px",
    //   name: "Заказы",
    //   selector: "order",
    // },
    // {
    //   width: "80px",
    //   name: "Сумма",
    //   selector: "total",
    // },
    {
      width: "80px",
      name: "Баллы",
      selector: "point",
    },
    {
      width: "40px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <Link
          disabled={selected.length > 0}
          to={"/user/" + row.id}
          className="me-2"
        >
          <IoCreateOutline size={22} />
        </Link>
      ),
    },
  ];
  const onUserDownload = useCallback((type) => {
    setLoadFile(true);
    getUserDownload(type)
      .then(async (res) => {
        NotificationManager.success(t("Идет генерация файла..."));
        const filename = `users_${moment().format(
          "YYYY_MM_DD_kk_mm_ss"
        )}.${type}`;

        // Получаем Blob из ответа
        const blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        // Создаем URL для Blob
        const url = window.URL.createObjectURL(blob);

        // Создаем элемент ссылки для скачивания
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;

        // Добавляем ссылку на документ и инициируем клик
        document.body.appendChild(a);
        a.click();

        // Удаляем ссылку после скачивания
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setTimeout(() => setLoadFile(false), 2000);
      })
      .catch((err) => {
        console.log(err);
        setLoadFile(false);
        NotificationManager.error(t("Ошибка при загрузке"));
      });
  }, []);

  const getData = useCallback(async () => {
    getUsers(searchParams)
      .then((res) => {
        setUsers((prev) => ({
          ...prev,
          loading: false,
          ...res.users,
        }));
        setStatistic(res.statistic);
      })
      .finally(() => setUsers((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams, brand]);

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0
                ? `${t("Выбрано")} ${selected.length}`
                : t("Пользователи")}
            </h5>
          </div>
          <div className="d-flex align-items-center">
            <Dropdown>
              <Dropdown.Toggle
                as={React.forwardRef(({ children, onClick }, ref) => (
                  <a
                    ref={ref}
                    className="btn-primary-outline me-3"
                    onClick={(e) => {
                      e.preventDefault();
                      onClick(e);
                    }}
                  >
                    <IoSaveOutline size={20} />
                  </a>
                ))}
              />
              <Dropdown.Menu align="end">
                <Dropdown.Item onClick={() => onUserDownload("yml")}>
                  YML
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onUserDownload("csv")}>
                  CSV
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onUserDownload("json")}>
                  JSON
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Link to="/users/create" className="btn-primary-outline me-3">
              <IoAdd size={18} />
            </Link>
            <Button
              disabled={selected.length === 0}
              className="btn-light"
              onClick={() => setModalDelete({ show: true, id: false })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label={t("Статус")}
                data={[
                  { title: "Все", value: "" },
                  { title: "Не подтвержденные", value: "blocked" },
                ]}
                value={searchParams.get("status") ?? ""}
                onClick={(e) => {
                  searchParams.set("status", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Сортировка"
                data={[
                  { title: "По дате регистрации: новые", value: "datenew" },
                  { title: "По дате регистрации: старые", value: "dateold" },
                  { title: "Заказов: больше", value: "ordermore" },
                  { title: "Заказов: меньше", value: "orderless" },
                ]}
                value={searchParams.get("sort") ?? "datenew"}
                onClick={(e) => {
                  searchParams.set("sort", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={6}>
              <Input
                ref={inputRef}
                placeholder={t("Найти")}
                className="w-100"
                onChange={(e) => {
                  searchParams.set("text", e);
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                value={searchParams.get("text") ?? ""}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
              {searchParams.get("text")?.length > 0 && (
                <Button
                  className="btn-light ms-3"
                  onClick={() => {
                    searchParams.delete("text");
                    setSearchParams(searchParams);
                    onSearch();
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <IoCloseOutline size={22} />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete, t]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  const onDeleteSelected = useCallback(() => {
    deleteUser(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success(
          t("Выбранные пользователи успешно удалены")
        );
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  if (users.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title={t("Пользователи")} />
      <Swiper
        grabCursor={true}
        speed={750}
        spaceBetween={10}
        className="pb-4 pt-2 px-2"
        slidesPerView={"auto"}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        breakpoints={{
          576: {
            slidesPerView: "auto",
          },
          768: {
            slidesPerView: "auto",
          },
          992: {
            slidesPerView: 3,
          },
        }}
      >
        <SwiperSlide>
          <Card className="h-100 shadow-none box-bg-green" body>
            <div className="mask"></div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="pe-3 ">
                <h5 className="mb-0 fw-6 h6">{t("Онлайн")}</h5>
                <div>
                  <h2 className="m-0 fs-15">{statistic.now}</h2>
                </div>
              </div>
              <div className="border-left"></div>
              <div className="d-flex flex-column justify-content-center align-items-end">
                <div>
                  <Badge bg="success" className=" mb-1 ms-1">
                    {statistic.all} все
                  </Badge>
                </div>
                <div>
                  <Badge bg="danger" className=" ms-2">
                    {statistic.new} новые
                  </Badge>
                </div>
              </div>
            </div>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card className="h-100 shadow-none box-bg-orange" body>
            <div className="mask"></div>
            <h5 className="mb-2 fw-6 h6">{t("Устройства")}</h5>
            <Row className="gx-1">
              <Col md={4} xl={4} xxl={4}>
                <p className="fs-08">{t("Android")}</p>
                <p className="fw-7 fs-09 mb-1">{statistic.android}</p>
              </Col>
              <Col md={4} xl={4} xxl={4}>
                <p className="fs-08">{t("IOS")}</p>
                <p className="fw-7 fs-09 mb-1">{statistic.ios}</p>
              </Col>
              <Col md={4} xl={4} xxl={4}>
                <p className="fs-08">{t("Windows")}</p>
                <p className="fw-7 fs-09 mb-1">{statistic.web}</p>
              </Col>
            </Row>
          </Card>
        </SwiperSlide>
      </Swiper>

      <DataTable
        columns={userColumns}
        onChange={(items) => setSelected(items)}
        data={users.items}
        header={header}
        selectable
        pagination={users.pagination}
      />
      <CustomModal
        title={`${t("Удаление")} ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              {t("Отмена")}
            </Button>
            <Button
              className="btn-danger"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              {t("Удалить")}
            </Button>
          </>
        }
      >
        {t("Вы точно хотите удалить пользователя?")}
      </CustomModal>
      <CustomModal
        title={t("Выгрузка пользователей")}
        show={loadFile}
        backdrop="static"
        closeButton={false}
        setShow={(e) => setLoadFile(e)}
      >
        <div className="fs-11">
          {t(
            "Идет загрузка пользователей из базы данных и создание файла, ожидайте (до 30 сек)..."
          )}
        </div>
      </CustomModal>
    </>
  );
};

export default Users;
