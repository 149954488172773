import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setStatuses: (state, action) => {
      state.data = action.payload;
    },
    resetStatus: (state, action) => {
      return { ...initialState };
    },
  },
});

export const { setStatuses, resetStatus } = statusSlice.actions;

export default statusSlice.reducer;
