import React, { useCallback, useEffect, useRef, useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import {
  IoChevronBackOutline,
  IoCreateOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import ImageCropper from "../../components/Cropper";
import DataTable from "../../components/DataTable";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Select from "../../components/UI/Select";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Textarea from "../../components/UI/Textarea";
import CustomModal from "../../components/utils/CustomModal";
import { convertWeight, customPrice, kkal } from "../../helpers/product";
import { createAddition } from "../../services/addition";
import { getIngredients } from "../../services/ingredient";

const AdditionCreate = () => {
  const [editImageProduct, setEditImageProduct] = useState({
    show: false,
    data: [],
  });
  const [editIngredient, setEditIngredient] = useState({
    show: false,
    data: {},
  });
  const [listStorage, setListIngredient] = useState({
    loading: true,
    show: false,
    items: [],
    selected: [],
  });
  const [modalIngredientDelete, setModalIngredientDelete] = useState({
    show: false,
    id: false,
  });

  const ingredientColumns = [
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Описание",
      selector: "desc",
    },
    {
      name: "БЖУ",
      cell: (row) => (
        <span>
          {row.energy.protein}
          <sup>Б</sup> {row.energy.fat}
          <sup>Ж</sup> {row.energy.carbohydrate}
          <sup>У</sup>
        </span>
      ),
    },
  ];

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const kkalData = useRef();

  useEffect(() => {
    if (listStorage.show) {
      if (!listStorage?.items?.length) {
        setListIngredient((e) => ({
          ...e,
          loading: true,
        }));
      }
      getIngredients()
        .then((res) =>
          setListIngredient((e) => ({
            ...e,
            loading: false,
            ...res,
          }))
        )
        .catch(() =>
          setListIngredient((e) => ({
            ...e,
            loading: false,
          }))
        );
    }
  }, [listStorage.show]);

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }
    createAddition(formData)
      .then(() => NotificationManager.success("Добавка успешно создана"))
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  const FormIngredient = ({ data, onChange }) => {
    return (
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={data.weight ?? 0}
              label="Вес, г"
              name="weight"
              type="number"
              onChange={(e) => onChange && onChange("weight", e)}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={data.price ?? 0}
              label="Цена"
              type="number"
              name="price"
              onChange={(e) => onChange && onChange("price", e)}
            />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Meta title="Создать добавку" />
      <div>
        <Link
          to="/catalog/additions"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <h3 className="mb-4">Создать добавку</h3>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Row>
                <Col md={8}>
                  <div className="mb-3">
                    <Input
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Input
                      label="Артикул"
                      name="code"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={0}
                      label="Порядок"
                      name="priority"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Select
                      classNameContainer="w-100"
                      label="Тип отправки"
                      data={[
                        { title: "Модификатор", value: null },
                        { title: "Блюдо", value: "product" },
                      ]}
                      value={data.options?.typeSend ?? null}
                      onClick={(e) => {
                        setValue("options.typeSend", e.value);
                      }}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Textarea
                      defaultValue={data.description}
                      label="Описание"
                      name="description"
                      errors={errors}
                      rows={6}
                      register={register}
                    />
                  </div>
                </Col>
                {/* <Col lg={6}>
                  <div className="mb-3">
                    <Select
                      label="Категория"
                      search
                      value={data.categoryId}
                      data={categories.items.map((e) => ({
                        title: e.title,
                        value: e.id,
                      }))}
                      onClick={(e) => setValue("categoryId", e.value)}
                    />
                  </div>
                </Col> */}
                {data.apiId && (
                  <Col md={12}>
                    <Input
                      defaultValue={data.apiId}
                      label="API ID"
                      name="apiId"
                      readOnly={false}
                    />
                  </Col>
                )}
              </Row>
            </Card.Body>
            {/* {data?.storages?.length > 0 ? (
              <>
                <div className="d-flex">
                  <Button
                    className="btn-primary me-3"
                    onClick={() =>
                      setListIngredient((e) => ({ ...e, show: true }))
                    }
                  >
                    Создать
                  </Button>
                  <Button
                    className="btn-primary-outline"
                    onClick={() =>
                      setListIngredient((e) => ({ ...e, show: true }))
                    }
                  >
                    Выбрать из списка
                  </Button>
                </div>
                <Accordion defaultActiveKey="0" className="mt-3">
                  {data.storages.map((e, index) => (
                    <Accordion.Item eventKey={index}>
                      <div className="d-flex align-items-center">
                        <Accordion.Header className="w-100">
                          {e?.title ??
                            e?.ingredient?.title ??
                            "Нет названия продукта"}
                        </Accordion.Header>
                        <a
                          className="mx-2"
                          onClick={() =>
                            setEditIngredient((info) => ({
                              show: !info.show,
                              data: e,
                            }))
                          }
                        >
                          <IoCreateOutline size={22} className="text-muted" />
                        </a>
                        <a
                          className="mx-2"
                          onClick={() =>
                            setModalIngredientDelete({
                              show: !modalIngredientDelete.show,
                              id: e.id,
                            })
                          }
                        >
                          <IoTrashOutline size={20} className="text-danger" />
                        </a>
                      </div>
                      <Accordion.Body>
                        <Row>
                          <Col md="auto">
                            <img
                              src="/images/empty-product-image.png"
                              width={40}
                              height={40}
                            />
                          </Col>
                          <Col>
                            <p className="text-muted fs-09">Описание</p>
                            {e?.desc ?? e?.ingredient?.desc ?? "Описания нет"}
                          </Col>
                          <Col
                            md="auto"
                            className="d-flex flex-column align-items-end"
                          >
                            <p className="text-muted fs-09">Цена</p>
                            {customPrice(e.price)}
                          </Col>
                          <Col
                            md="auto"
                            className="d-flex flex-column align-items-end"
                          >
                            <p className="text-muted fs-09">Вес</p>
                            {convertWeight(e.weight ?? 0)}
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </>
            ) : (
              <p className="my-5 text-muted text-center">
                Состав отсутствует
                <div className="mt-3 d-flex justify-content-center">
                  <Link className="btn-primary me-3" to="/ingredient/create">
                    Создать
                  </Link>
                  <Button
                    className="btn-primary-outline"
                    onClick={() =>
                      setListIngredient((e) => ({ ...e, show: true }))
                    }
                  >
                    Выбрать из списка
                  </Button>
                </div>
              </p>
            )} */}
          </Card>
        </Col>
        <Col md={4} className="position-relative">
          <div className="position-sticky top-1">
            <Card className="mb-3" body>
              {/* <DragDropFile
                file={data.file}
                media={data.medias}
                onChange={(e) =>
                  setEditImageProduct((info) => ({
                    show: !info.show,
                    data: e,
                  }))
                }
              /> */}
              <DragDropFile
                col={12}
                maxAspect={1}
                main
                multiple
                file={data.file}
                onMain={(e) => setValue("main", e)}
                onChange={(e) => setValue("file", e)}
                onDelete={(e) => setValue("file", e.file)}
                sizeText="Рекомендовано: 500 х 500 px - Пропорции 1/1"
              />
            </Card>
            <Card body className="mb-3">
              <p className="fs-08">
                Энергетическая ценность в 100г -{" "}
                <span className="text-success">
                  {kkalData?.current?.kkal ?? 0}
                </span>{" "}
                ккал
              </p>
              <Row className="gx-2">
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.protein ?? 0}
                      label="Белки, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.fat ?? 0}
                      label="Жиры, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.carbohydrate ?? 0}
                      label="Углеводы, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.kkalAll ?? 0}
                      label="Всего ккал, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={kkalData?.current?.weight ?? 0}
                      label="Вес, г"
                      name="energy.weight"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-3">
              <p className="fs-08">Цена</p>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={0}
                      label="Цена"
                      name="price"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={0}
                      label="Скидка"
                      name="discount"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Button
              className="btn-primary align-self-end w-100 mt-3"
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </Button>
          </div>
        </Col>
      </Row>
      <ImageCropper
        file={editImageProduct.data[0]}
        show={editImageProduct.show}
        setShow={(e) => setEditImageProduct((info) => ({ ...info, show: e }))}
        onComplete={(e) => {
          e && setValue("file", e.file);
          e && setValue("medias", e.blob);
        }}
      />

      <CustomModal
        size="lg"
        title="Редактировать состав"
        show={editIngredient.show}
        setShow={(e) => setEditIngredient((list) => ({ ...list, show: e }))}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setEditIngredient((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                let newArray = data.storages.map((e) =>
                  e.id === editIngredient.data.id ? editIngredient.data : e
                );
                setValue("storages", newArray);
                setEditIngredient({ show: false, data: {} });
                kkalData.current = kkal(newArray);
              }}
            >
              Сохранить продукт
            </Button>
          </>
        }
      >
        <FormIngredient
          data={editIngredient.data}
          onChange={(title, value) =>
            setEditIngredient((info) => {
              info.data[title] = value;
              return info;
            })
          }
        />
      </CustomModal>
      <CustomModal
        title="Список продукции"
        show={listStorage.show}
        setShow={(e) => setListIngredient((list) => ({ ...list, show: e }))}
        classNameBody="py-2 px-0"
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setListIngredient((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              disabled={listStorage?.selected?.length === 0}
              onClick={() => {
                if (listStorage?.selected?.length > 0) {
                  let newArray = data.storages.concat(
                    listStorage.selected.map((e) => ({
                      productId: data.id,
                      ingredientId: e.item.id,
                      ingredient: e.item,
                      weight: 0,
                      price: 0,
                    }))
                  );
                  setValue("storages", newArray);
                  setListIngredient((e) => ({
                    ...e,
                    items: listStorage.items.filter(
                      (e) =>
                        !listStorage.selected.find((e2) => e2.item.id === e.id)
                    ),
                    selected: [],
                    show: false,
                  }));
                  kkalData.current = kkal(newArray);
                }
              }}
            >
              Добавить выбранное
            </Button>
          </>
        }
      >
        {listStorage.loading ? (
          <Loader className="d-flex m-auto my-5" />
        ) : listStorage?.items.length === 0 ? (
          <p className="my-5 text-muted text-center">Пока нет продукции</p>
        ) : (
          <DataTable
            columns={ingredientColumns}
            data={listStorage.items}
            onChange={(items) =>
              setListIngredient((e) => ({ ...e, selected: items }))
            }
            lite
            selectable
          />
        )}
      </CustomModal>
      <CustomModal
        title="Удаление элемента"
        show={modalIngredientDelete.show}
        setShow={(e) => setModalIngredientDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalIngredientDelete({
                  show: !modalIngredientDelete.show,
                  id: false,
                })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() => {
                setValue(
                  "storages",
                  data.storages.filter((e) => e.id != modalIngredientDelete.id)
                );
                setListIngredient((e) => ({
                  ...e,
                  items: [
                    ...listStorage.items,
                    data.storages.find(
                      (e) => e.id === modalIngredientDelete.id
                    ),
                  ],
                  selected: [],
                }));
                setModalIngredientDelete({ show: false, id: false });
              }}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить состав из товара?
      </CustomModal>
    </>
  );
};

export default AdditionCreate;
