import React from "react";
import Meta from "../components/Meta";
import { Col, Container, Row } from "react-bootstrap";

const PolicyCookie = () => {
  return (
    <main>
      <Meta
        title={"Политика использования файлов сookies"}
        description={"Политика использования файлов сookies"}
      />
      <Container>
        <Row className="d-flex justify-content-center">
          <Col md={7}>
            <h1 className="mb-4">Политика использования файлов сookies</h1>
            <p>
              <h5>Что такое куки (cookies)?</h5>
              <br />
              Куки - это небольшие текстовые файлы, которые сайт сохраняет на
              вашем компьютере или на мобильном устройстве, когда вы заходите на
              сайт. Куки широко используются как для обеспечения корректной или
              более эффективной работы сайта, так и для предоставления нужной
              информации владельцам сайта.
              <br />
              <br />
              <h5>Как мы используем куки?</h5>
              <br />
              Мы используем куки для того, чтобы узнавать вас, когда вы заходите
              на наш сайт. Это означает, что вам не нужно авторизовываться
              каждый раз когда вы заходите на наш сайт, и мы можем запомнить
              ваши настройки и предпочтения.
              <br />
              <br />
              <h5>Сторонние куки</h5>
              <br />
              В дополнение к нашим кукам, мы сотрудничаем со сторонними
              компаниями, которые используя собственные куки помогают нам
              анализировать работу нашего сайта, чтобы мы могли оптимизировать
              его для вашего удобства.
              <br />
              Большинство браузеров позволяют вам увидеть какие именно куки
              сохранены у вас, и удалить их или заблокировать по отдельности.
              Необходимо учесть, что если вы решите удалить куки, то в этом
              случае ваши настройки и предпочтения будут нами утеряны.
            </p>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default PolicyCookie;
