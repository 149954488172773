import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import { customPrice } from "../../../helpers/product";

const SmspilotForm = ({ data, onSubmit }) => {
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  return (
    <Card body className="mb-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <span>
          <h5 className="mb-4">Настройки</h5>
        </span>
        <div>
          <h5 className="text-right">
            <p className="text-muted fs-07 fw-4">Баланс</p>
            <span
              className={
                form?.relationModule?.options?.balance > 0
                  ? "text-success"
                  : "text-danger"
              }
            >
              {customPrice(form?.relationModule?.options?.balance ?? 0)}
            </span>
          </h5>
          <a
            className="fs-08"
            target="_blank"
            href="https://smspilot.ru/my-order.php"
          >
            <b>Пополнить счет</b>
          </a>
        </div>
      </div>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="Токен"
              name="relationModule.options.token"
              errors={errors}
              defaultValue={form?.relationModule?.options?.token}
              register={register}
              validation={{ required: "Обязательное поле" }}
            />
            <small className="text-muted fs-08">
              Получите токен в аккаунте smspilot после регистрации
            </small>
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="Отправитель"
              name="relationModule.options.from"
              placeholder="По умолчанию 'INFORM'"
              errors={errors}
              defaultValue={form?.relationModule?.options?.from ?? "INFORM"}
              register={register}
            />
            <small className="text-muted fs-08">
              Уникальное название отправителя. Можно зарегистрировать только на
              юр лицо.{" "}
              <a
                className="text-success"
                target="_blank"
                href="https://smspilot.ru/my-sender.php"
              >
                <b>Перейти к созданию</b>
              </a>
            </small>
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="Подпись"
              name="relationModule.options.signature"
              placeholder="Введите название и ссылку"
              errors={errors}
              defaultValue={form?.relationModule?.options?.signature}
              register={register}
              maxLength={20}
              validation={{
                maxLength: {
                  value: 15,
                  message: "Максимально 15 символов",
                },
              }}
            />
            <small className="text-muted fs-08">
              Например: Код подтверждения 1234{" "}
              <span className="text-success">YooApp</span>
              <br />
              <span>
                Обязательно добавьте новый шаблон в{" "}
                <a
                  className="text-success"
                  target="_blank"
                  href="https://smspilot.ru/my-template.php?action=add"
                >
                  <b>настройках smspilot</b>
                </a>
              </span>
            </small>
          </div>
        </Col>
        <Col lg={6}>
          <b className="mb-2 d-block">Популярные вопросы</b>
          <div className="fs-08">
            <div className="mb-1">
              1. Почему СМС не приходит, даже если баланс положительный?
            </div>
            <span className="text-muted">Ответ:</span> Убедитесь что номер
            телефона написан верно. Проверьте{" "}
            <a
              className="text-success"
              target="_blank"
              href="https://smspilot.ru/my-report.php?view=all"
            >
              <b>отчеты</b>
            </a>{" "}
            в аккаунте{" "}
            <a
              className="text-success"
              target="_blank"
              href="https://smspilot.ru/my-report.php?view=all"
            >
              <b>smspilot</b>
            </a>
            , если были ошибки при отправке, в аккаунте вы сможете узнать
            информацию по каждой отправленной смс.
            <div className="mt-2">2. Как контролировать отправку смс?</div>
            <span className="text-muted">Ответ:</span> Перейдите на страницу{" "}
            <a
              className="text-success"
              target="_blank"
              href="https://smspilot.ru/my-settings.php?tab=api"
            >
              <b>настроек API</b>
            </a>
            <div className="mt-2">3. Как узнать расценки?</div>
            <span className="text-muted">Ответ:</span> Перейти на страницу{" "}
            <a
              className="text-success"
              target="_blank"
              href="https://smspilot.ru/my-price.php"
            >
              <b>мой тариф</b>
            </a>
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </Card>
  );
};
export default SmspilotForm;
