import { $authApi } from ".";
import { apiRoutes } from "../config/api";
const getUsers = async (data) => {
  const response = await $authApi.get(apiRoutes.USER, {
    params: data,
  });
  return response?.data;
};
const getUser = async (id) => {
  const response = await $authApi.get(apiRoutes.USER_ONE, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editUser = async (user) => {
  const response = await $authApi.put(apiRoutes.USER, user);
  return response?.data;
};
const createUser = async (user) => {
  const response = await $authApi.post(apiRoutes.USER, user);
  return response?.data;
};
const createPoint = async (user) => {
  const response = await $authApi.post(apiRoutes.USER_CREATE_POINT, user);
  return response?.data;
};
const updatePoint = async (user) => {
  const response = await $authApi.put(apiRoutes.USER_UPDATE_POINT, user);
  return response?.data;
};
const blockedUser = async (data) => {
  const response = await $authApi.post(apiRoutes.USER_BLOCKED, data);
  return response?.data;
};
const deleteUser = async (id) => {
  const response = await $authApi.delete(apiRoutes.USER, {
    data: { id },
  });
  return response?.data;
};
const getUserDownload = async (type) => {
  const response = await $authApi.get(apiRoutes.USER_DOWNLOAD, {
    params: { type },
    responseType: "blob",
  });

  return response;
};
export { deleteUser, blockedUser, createUser, getUserDownload, editUser, getUser, getUsers, createPoint, updatePoint };
