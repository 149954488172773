import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getModules = async (data) => {
  const response = await $authApi.get(apiRoutes.MODULES, { params: data });
  return response?.data;
};
const getModule = async (data) => {
  const response = await $authApi.get(apiRoutes.MODULE, {
    params: data,
  });
  return response?.data;
};
const getBrandOptions = async (data) => {
  const response = await $authApi.get(apiRoutes.MODULE_BRAND_OPTIONS, {
    params: data,
  });
  return response?.data;
};
const editModule = async (data) => {
  const response = await $authApi.post(apiRoutes.MODULE_EDIT, data);
  return response?.data;
};
const editModuleWeb = async (data) => {
  const response = await $authApi.post(apiRoutes.MODULE_WEB, data);
  return response?.data;
};
const editModuleShowcase = async (data) => {
  const response = await $authApi.post(apiRoutes.MODULE_SHOWCASE, data);
  return response?.data;
};
const editModuleApp = async (data) => {
  const response = await $authApi.post(apiRoutes.MODULE_APP, data);
  return response?.data;
};
const editModuleBrand = async (data) => {
  const response = await $authApi.post(apiRoutes.MODULE_BRAND, data);
  return response?.data;
};
const createModule = async (data) => {
  const response = await $authApi.post(apiRoutes.MODULES, data);
  return response?.data;
};
const statusModule = async (data) => {
  const response = await $authApi.put(apiRoutes.MODULES, data);
  return response?.data;
};
const deleteModule = async (data) => {
  const response = await $authApi.delete(apiRoutes.MODULES, { data });
  return response?.data;
};
const resetModule = async (data) => {
  const response = await $authApi.post(apiRoutes.MODULE_RESET, data);
  return response?.data;
};
const updateDomain = async (data) => {
  const response = await $authApi.post(apiRoutes.MODULE_DOMAIN_UPDATE, data);
  return response?.data;
};
export {
  getModules,
  editModuleWeb,
  statusModule,
  editModuleShowcase,
  resetModule,
  updateDomain,
  editModuleApp,
  editModuleBrand,
  getModule,
  editModule,
  createModule,
  deleteModule,
  getBrandOptions,
};
