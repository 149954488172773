import React, { memo } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CustomModal = memo(
  ({
    className = "",
    classNameBody = "",
    classNameFooter = "",
    setShow,
    show = false,
    size,
    classNameHeader = "",
    title,
    children,
    footer,
    full = false,
    backdrop,
    closeButton = true,
  }) => {
    const { t } = useTranslation();
    return show ? (
      <Modal
        className={className}
        show={show}
        onHide={() => setShow(false)}
        centered
        size={size}
        backdrop={backdrop}
        fullscreen={full}
      >
        <Modal.Header
          closeButton={closeButton}
          onHide={() => setShow(false)}
          className={classNameHeader}
        >
          {title ? <h5>{t(title)}</h5> : null}
        </Modal.Header>
        <Modal.Body className={classNameBody}>{children}</Modal.Body>
        {footer && (
          <Modal.Footer className={classNameFooter}>{footer}</Modal.Footer>
        )}
      </Modal>
    ) : (
      ""
    );
  }
);

export default CustomModal;
