import React, { useCallback, useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import { createCallback } from "../../services/callback";
import Select from "../../components/UI/Select";

const countries = [
  // { code: "AF", name: "Афганистан", mask: "+93(99)999-9999" },
  { code: "AL", name: "Албания", mask: "+355(99)999-9999" },
  { code: "DZ", name: "Алжир", mask: "+213(99)999-9999" },
  { code: "AD", name: "Андорра", mask: "+376(999)999" },
  { code: "AO", name: "Ангола", mask: "+244(999)999-999" },
  { code: "AR", name: "Аргентина", mask: "+54(999)999-9999" },
  { code: "AM", name: "Армения", mask: "+374(99)999-999" },
  { code: "AU", name: "Австралия", mask: "+61(999)999-9999" },
  { code: "AT", name: "Австрия", mask: "+43(999)999-9999" },
  { code: "AZ", name: "Азербайджан", mask: "+994(99)999-99-99" },
  { code: "BH", name: "Бахрейн", mask: "+973(9999)9999" },
  { code: "BD", name: "Бангладеш", mask: "+880(99)999-999" },
  { code: "BY", name: "Беларусь", mask: "+375(99)999-99-99" },
  { code: "BE", name: "Бельгия", mask: "+32(999)999-999" },
  { code: "BZ", name: "Белиз", mask: "+501(999)9999" },
  { code: "BJ", name: "Бенин", mask: "+229(99)99-99-99" },
  { code: "BT", name: "Бутан", mask: "+975(99)999-999" },
  { code: "BO", name: "Боливия", mask: "+591(999)999-9999" },
  { code: "BA", name: "Босния и Герцеговина", mask: "+387(99)999-999" },
  { code: "BW", name: "Ботсвана", mask: "+267(99)999-999" },
  { code: "BR", name: "Бразилия", mask: "+55(99)9999-9999" },
  { code: "BN", name: "Бруней", mask: "+673(999)9999" },
  { code: "BG", name: "Болгария", mask: "+359(99)999-9999" },
  { code: "BF", name: "Буркина-Фасо", mask: "+226(99)99-99-99" },
  { code: "BI", name: "Бурунди", mask: "+257(99)99-99-99" },
  { code: "KH", name: "Камбоджа", mask: "+855(99)999-999" },
  { code: "CM", name: "Камерун", mask: "+237(99)99-99-99" },
  { code: "CA", name: "Канада", mask: "+1(999)999-9999" },
  { code: "CV", name: "Кабо-Верде", mask: "+238(999)99-99" },
  { code: "CF", name: "ЦАР", mask: "+236(99)99-99-99" },
  { code: "TD", name: "Чад", mask: "+235(99)99-99-99" },
  { code: "CL", name: "Чили", mask: "+56(9)9999-9999" },
  { code: "CN", name: "Китай", mask: "+86(999)9999-9999" },
  { code: "CO", name: "Колумбия", mask: "+57(999)999-9999" },
  { code: "KM", name: "Коморы", mask: "+269(999)99-99" },
  { code: "CG", name: "Конго", mask: "+242(99)999-9999" },
  { code: "CR", name: "Коста-Рика", mask: "+506(9999)9999" },
  { code: "HR", name: "Хорватия", mask: "+385(99)999-9999" },
  { code: "CU", name: "Куба", mask: "+53(99)999-9999" },
  { code: "CY", name: "Кипр", mask: "+357(99)999-999" },
  { code: "CZ", name: "Чехия", mask: "+420(999)999-999" },
  { code: "DK", name: "Дания", mask: "+45(99)99-99-99" },
  { code: "DJ", name: "Джибути", mask: "+253(99)99-99-99" },
  { code: "DM", name: "Доминика", mask: "+1(767)999-9999" },
  { code: "DO", name: "Доминиканская Республика", mask: "+1(809)999-9999" },
  { code: "EC", name: "Эквадор", mask: "+593(99)999-9999" },
  { code: "EG", name: "Египет", mask: "+20(999)999-9999" },
  { code: "SV", name: "Сальвадор", mask: "+503(9999)9999" },
  { code: "GQ", name: "Экваториальная Гвинея", mask: "+240(99)999-9999" },
  { code: "ER", name: "Эритрея", mask: "+291(9)999-999" },
  { code: "EE", name: "Эстония", mask: "+372(9999)9999" },
  { code: "SZ", name: "Эсватини", mask: "+268(99)99-99-99" },
  { code: "ET", name: "Эфиопия", mask: "+251(99)999-9999" },
  { code: "FJ", name: "Фиджи", mask: "+679(999)9999" },
  { code: "FI", name: "Финляндия", mask: "+358(99)999-9999" },
  { code: "FR", name: "Франция", mask: "+33(9)99-99-99-99" },
  { code: "GA", name: "Габон", mask: "+241(9)99-99-99" },
  { code: "GM", name: "Гамбия", mask: "+220(999)9999" },
  { code: "GE", name: "Грузия", mask: "+995(99)999-9999" },
  { code: "DE", name: "Германия", mask: "+49(9999)999-9999" },
  { code: "GH", name: "Гана", mask: "+233(99)999-9999" },
  { code: "GR", name: "Греция", mask: "+30(999)999-9999" },
  { code: "GD", name: "Гренада", mask: "+1(473)999-9999" },
  { code: "GT", name: "Гватемала", mask: "+502(9999)9999" },
  { code: "GN", name: "Гвинея", mask: "+224(99)999-9999" },
  { code: "GW", name: "Гвинея-Бисау", mask: "+245(999)999-999" },
  { code: "GY", name: "Гайана", mask: "+592(999)9999" },
  { code: "HT", name: "Гаити", mask: "+509(99)99-9999" },
  { code: "HN", name: "Гондурас", mask: "+504(9999)9999" },
  { code: "HU", name: "Венгрия", mask: "+36(99)999-9999" },
  { code: "IS", name: "Исландия", mask: "+354(999)9999" },
  { code: "IN", name: "Индия", mask: "+91(9999)999-999" },
  { code: "ID", name: "Индонезия", mask: "+62(999)999-9999" },
  { code: "IR", name: "Иран", mask: "+98(999)999-9999" },
  { code: "IQ", name: "Ирак", mask: "+964(999)999-9999" },
  { code: "IE", name: "Ирландия", mask: "+353(99)999-9999" },
  { code: "IL", name: "Израиль", mask: "+972(99)999-9999" },
  { code: "IT", name: "Италия", mask: "+39(999)999-9999" },
  { code: "JM", name: "Ямайка", mask: "+1(876)999-9999" },
  { code: "JP", name: "Япония", mask: "+81(99)9999-9999" },
  { code: "JO", name: "Иордания", mask: "+962(9)9999-9999" },
  { code: "KZ", name: "Казахстан", mask: "+7(999)999-99-99" },
  { code: "KE", name: "Кения", mask: "+254(999)999-999" },
  { code: "KI", name: "Кирибати", mask: "+686(99)999-999" },
  { code: "KP", name: "КНДР", mask: "+850(99)999-9999" },
  { code: "KR", name: "Корея", mask: "+82(99)999-9999" },
  { code: "KW", name: "Кувейт", mask: "+965(999)99999" },
  { code: "KG", name: "Кыргызстан", mask: "+996(99)999-9999" },
  { code: "LA", name: "Лаос", mask: "+856(99)999-9999" },
  { code: "LV", name: "Латвия", mask: "+371(99)999-999" },
  { code: "LB", name: "Ливан", mask: "+961(99)999-999" },
  { code: "LS", name: "Лесото", mask: "+266(99)999-9999" },
  { code: "LR", name: "Либерия", mask: "+231(99)999-9999" },
  { code: "LY", name: "Ливия", mask: "+218(99)999-9999" },
  { code: "LI", name: "Лихтенштейн", mask: "+423(999)999-999" },
  { code: "LT", name: "Литва", mask: "+370(99)999-9999" },
  { code: "LU", name: "Люксембург", mask: "+352(999)999-999" },
  { code: "MG", name: "Мадагаскар", mask: "+261(99)99-99999" },
  { code: "MW", name: "Малави", mask: "+265(99)999-9999" },
  { code: "MY", name: "Малайзия", mask: "+60(99)999-99999" },
  { code: "MV", name: "Мальдивы", mask: "+960(999)9999" },
  { code: "ML", name: "Мали", mask: "+223(99)99-99-99" },
  { code: "MT", name: "Мальта", mask: "+356(9999)9999" },
  { code: "MH", name: "Маршалловы Острова", mask: "+692(999)9999" },
  { code: "MR", name: "Мавритания", mask: "+222(99)99-99-99" },
  { code: "MU", name: "Маврикий", mask: "+230(999)9999" },
  { code: "MX", name: "Мексика", mask: "+52(999)999-9999" },
  { code: "FM", name: "Микронезия", mask: "+691(999)9999" },
  { code: "MD", name: "Молдова", mask: "+373(99)999-999" },
  { code: "MC", name: "Монако", mask: "+377(99)999-999" },
  { code: "MN", name: "Монголия", mask: "+976(99)99-9999" },
  { code: "ME", name: "Черногория", mask: "+382(99)999-999" },
  { code: "MA", name: "Марокко", mask: "+212(99)9999-999" },
  { code: "MZ", name: "Мозамбик", mask: "+258(99)999-9999" },
  { code: "MM", name: "Мьянма", mask: "+95(99)999-9999" },
  { code: "NA", name: "Намибия", mask: "+264(99)999-9999" },
  { code: "NR", name: "Науру", mask: "+674(999)9999" },
  { code: "NP", name: "Непал", mask: "+977(99)999-9999" },
  { code: "NL", name: "Нидерланды", mask: "+31(99)999-9999" },
  { code: "NZ", name: "Новая Зеландия", mask: "+64(99)999-9999" },
  { code: "NI", name: "Никарагуа", mask: "+505(9999)9999" },
  { code: "NE", name: "Нигер", mask: "+227(99)99-99-99" },
  { code: "NG", name: "Нигерия", mask: "+234(99)9999-9999" },
  { code: "MK", name: "Северная Македония", mask: "+389(99)999-999" },
  { code: "NO", name: "Норвегия", mask: "+47(99)99-99-99" },
  { code: "OM", name: "Оман", mask: "+968(99)999-999" },
  { code: "PK", name: "Пакистан", mask: "+92(999)999-9999" },
  { code: "PW", name: "Палау", mask: "+680(999)9999" },
  { code: "PA", name: "Панама", mask: "+507(999)9999" },
  { code: "PG", name: "Папуа — Новая Гвинея", mask: "+675(999)9999" },
  { code: "PY", name: "Парагвай", mask: "+595(99)999-9999" },
  { code: "PE", name: "Перу", mask: "+51(99)999-9999" },
  { code: "PH", name: "Филиппины", mask: "+63(999)999-9999" },
  { code: "PL", name: "Польша", mask: "+48(99)999-9999" },
  { code: "PT", name: "Португалия", mask: "+351(99)999-9999" },
  { code: "QA", name: "Катар", mask: "+974(9999)9999" },
  { code: "RO", name: "Румыния", mask: "+40(99)999-9999" },
  { code: "RU", name: "Россия", mask: "+7(999)999-99-99" },
  { code: "RW", name: "Руанда", mask: "+250(99)999-9999" },
  { code: "KN", name: "Сент-Китс и Невис", mask: "+1(869)999-9999" },
  { code: "LC", name: "Сент-Люсия", mask: "+1(758)999-9999" },
  { code: "VC", name: "Сент-Винсент и Гренадины", mask: "+1(784)999-9999" },
  { code: "WS", name: "Самоа", mask: "+685(99)9999" },
  { code: "SM", name: "Сан-Марино", mask: "+378(999)999-999" },
  { code: "ST", name: "Сан-Томе и Принсипи", mask: "+239(99)999-999" },
  { code: "SA", name: "Саудовская Аравия", mask: "+966(99)999-9999" },
  { code: "SN", name: "Сенегал", mask: "+221(99)999-9999" },
  { code: "RS", name: "Сербия", mask: "+381(99)999-9999" },
  { code: "SC", name: "Сейшелы", mask: "+248(99)99-99-99" },
  { code: "SL", name: "Сьерра-Леоне", mask: "+232(99)999-999" },
  { code: "SG", name: "Сингапур", mask: "+65(9999)9999" },
  { code: "SK", name: "Словакия", mask: "+421(99)999-9999" },
  { code: "SI", name: "Словения", mask: "+386(99)999-9999" },
  { code: "SB", name: "Соломоновы Острова", mask: "+677(99)9999" },
  { code: "SO", name: "Сомали", mask: "+252(99)999-9999" },
  { code: "ZA", name: "ЮАР", mask: "+27(99)999-9999" },
  { code: "SS", name: "Южный Судан", mask: "+211(99)999-9999" },
  { code: "ES", name: "Испания", mask: "+34(999)999-999" },
  { code: "LK", name: "Шри-Ланка", mask: "+94(99)999-9999" },
  { code: "SD", name: "Судан", mask: "+249(99)999-9999" },
  { code: "SR", name: "Суринам", mask: "+597(999)9999" },
  { code: "SE", name: "Швеция", mask: "+46(99)999-9999" },
  { code: "CH", name: "Швейцария", mask: "+41(99)999-9999" },
  { code: "SY", name: "Сирия", mask: "+963(99)999-9999" },
  { code: "TW", name: "Тайвань", mask: "+886(99)999-9999" },
  { code: "TJ", name: "Таджикистан", mask: "+992(99)999-9999" },
  { code: "TZ", name: "Танзания", mask: "+255(99)999-9999" },
  { code: "TH", name: "Таиланд", mask: "+66(99)999-9999" },
  { code: "TL", name: "Тимор-Лешти", mask: "+670(999)9999" },
  { code: "TG", name: "Того", mask: "+228(99)999-999" },
  { code: "TO", name: "Тонга", mask: "+676(999)9999" },
  { code: "TT", name: "Тринидад и Тобаго", mask: "+1(868)999-9999" },
  { code: "TN", name: "Тунис", mask: "+216(99)999-999" },
  { code: "TR", name: "Турция", mask: "+90(999)999-9999" },
  { code: "TM", name: "Туркменистан", mask: "+993(99)999-9999" },
  { code: "TV", name: "Тувалу", mask: "+688(999)9999" },
  { code: "UG", name: "Уганда", mask: "+256(99)999-9999" },
  // { code: "UA", name: "Украина", mask: "+380(99)999-9999" },
  { code: "AE", name: "ОАЭ", mask: "+971(99)999-9999" },
  { code: "GB", name: "Великобритания", mask: "+44(9999)999-999" },
  { code: "US", name: "США", mask: "+1(999)999-9999" },
  { code: "UY", name: "Уругвай", mask: "+598(99)999-9999" },
  { code: "UZ", name: "Узбекистан", mask: "+998(99)999-9999" },
  { code: "VU", name: "Вануату", mask: "+678(999)9999" },
  { code: "VA", name: "Ватикан", mask: "+379(999)999-999" },
  { code: "VE", name: "Венесуэла", mask: "+58(999)999-9999" },
  { code: "VN", name: "Вьетнам", mask: "+84(99)999-9999" },
  { code: "YE", name: "Йемен", mask: "+967(99)999-9999" },
  { code: "ZM", name: "Замбия", mask: "+260(99)999-9999" },
  { code: "ZW", name: "Зимбабве", mask: "+263(99)999-9999" },
];

const Reg = () => {
  const auth = useSelector((state) => state?.auth);
  const options = useSelector((state) => state?.settings);
  const optionsBrand = useSelector((state) => state.settings.brand);
  const [country, setCountry] = useState({
    code: "RU",
    name: "Россия",
    mask: "+7(999)999-99-99",
  });
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (auth.isAuth) {
      return navigate("/");
    }
  }, [auth.isAuth]);

  const {
    register,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
  } = useForm({ mode: "all", reValidateMode: "onSubmit" });

  const onSubmit = useCallback(
    (data) => {
      createCallback({ ...data, ...options, country }).finally(() => {
        setValue("name", "");
        setValue("phone", "");
        setShow(!show);
      });
    },
    [show, country, options]
  );

  return (
    <>
      <Meta title="Регистрация" />
      <Row className="gx-0 hv-100-important">
        <Col lg={8} md={7} className="login-info d-none d-md-flex">
          <div className="flex-column d-flex align-self-center justify-content-center align-items-center">
            <img src="/logo.png" height={80} />
            <h2 className="mt-4 mb-2 text-center">
              {optionsBrand?.title ?? "YooApp"}
            </h2>
            <h4 className="h6 fw-5 text-center">
              {optionsBrand?.desc ??
                "Платформа для автоматизации процессов в общепите"}
            </h4>
            <img src="./images/auth/login.svg" width="80%" className="mt-3" />
          </div>
        </Col>
        <Col lg={4} md={5}>
          <div className="login">
            <Form className="login-form" onSubmit={handleSubmit(onSubmit)}>
              <Alert variant="success" show={show}>
                Заявка успешно отправлена, мы свяжемся с вами по данному номеру
                для предоставления закрытого доступа
              </Alert>
              <h3 className="mb-2 h5 fw-7 w-100">Получите демо доступ</h3>
              <p className="fs-08 mb-4">
                Мы свяжемся с вами по вашей заявке в течении 15 минут. <br />
                Режим работы с <b>09:00</b> до <b>21:00</b> круглосуточно.
              </p>
              {/* <p className="mb-4 text-muted fs-08">
                Уже есть профиль?{" "}
                <Link className="text-success" to="/login">
                  Войти
                </Link>
              </p> */}
              <Input
                autoFocus
                label="Имя"
                name="name"
                placeholder="Введите имя"
                errors={errors}
                register={register}
                validation={{
                  required: "Введите имя",
                  maxLength: {
                    value: 30,
                    message: "Максимально 30 символов",
                  },
                }}
              />
              <div className="mt-4 d-flex align-items-center">
                <div className="w-100">
                  <Input
                    mask={country.mask}
                    label="Номер телефона"
                    name="phone"
                    placeholder={country.mask}
                    errors={errors}
                    register={register}
                    validation={{
                      required: "Введите номер телефона",
                      maxLength: {
                        value: 18,
                        message: "Максимально 18 символов",
                      },
                    }}
                  />
                </div>
                <div className="ps-3">
                  <Select
                    search
                    onClick={(e) => {
                      setValue("phone", "");
                      setCountry(e.value);
                    }}
                    value={country.code}
                    data={countries.map((e) => ({
                      title: e.code,
                      subTitle: e.name,
                      value: e,
                    }))}
                  />
                </div>
              </div>
              {/* <div className="mt-4">
                <Input
                  label="Пароль"
                  type="password"
                  name="password"
                  errors={errors}
                  register={register}
                  validation={{
                    required: "Введите пароль",
                    minLength: {
                      value: 4,
                      message:
                        "Минимальный пароль должен состоять из 4-х символов",
                    },
                  }}
                />
              </div> */}
              <Button
                type="submit"
                className="btn-primary mt-3 w-100"
                disabled={!isValid}
              >
                Оставить заявку
              </Button>
              <Link to="/" className="btn btn-light mt-3 w-100">
                Войти в профиль
              </Link>
              {!optionsBrand && (
                <p className="mt-3 text-muted text-center fs-07">
                  Отправляя заявку, я соглашаюсь с{" "}
                  <Link to="/policy" className="text-success">
                    Политикой конфиденциальности
                  </Link>
                  .
                </p>
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Reg;
