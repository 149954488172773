import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { createPromo } from "../../services/promo";
import { getProductList } from "../../services/product";
import Loader from "../../components/UI/Loader";
import promos from "../../helpers/promos.json";
import { getModule } from "../../services/module";
import { getCategories } from "../../services/category";

const PromoCreate = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState({
    loading: true,
    items: [],
  });
  const [categories, setCategories] = useState({
    loading: true,
    items: [],
  });
  const [module, setModule] = useState({ data: false, loading: true });

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onSubmit",
    defaultValues: {
      type: promos[0].value,
      desc: promos[0].desc,
      error: promos[0].error,
      options: { summed: true, exceptions: [] },
      status: 1,
    },
  });

  const data = useWatch({ control });

  const discount =
    data?.options?.apiId &&
    module?.data?.relationModule?.options?.discounts?.length > 0
      ? module.data.relationModule.options.discounts.find(
          (e) => e.id === data?.options?.apiId
        )
      : false;

  useLayoutEffect(() => {
    getModule({ moduleId: 1 })
      .then(
        (res) =>
          res &&
          setModule({
            loading: false,
            data: res,
          })
      )
      .catch(() =>
        setModule((res) => ({
          ...res,
          loading: false,
        }))
      );

    getCategories({ limit: 300 })
      .then((res) => {
        if (res?.items?.length > 0) {
          let array = res.items.map((e) => ({ title: e.title, value: e.id }));
          setCategories((prev) => ({
            ...prev,
            loading: false,
            items: array,
          }));
        }
      })
      .finally(() => setCategories((prev) => ({ ...prev, loading: false })));

    getProductList()
      .then((res) => {
        if (res?.length > 0) {
          let array = res.map((e) => ({ title: e.title, value: e.id }));
          setProducts((prev) => ({
            ...prev,
            loading: false,
            items: array,
          }));
        }
      })
      .finally(() => setProducts((prev) => ({ ...prev, loading: false })));
  }, []);

  useEffect(() => {
    if (data?.type && promos?.length > 0) {
      let promo = promos.find((e) => e.value === data.type);
      if (promo) {
        reset({
          ...data,
          type: promo.value,
          desc: promo.desc,
          error: promo.error,
        });
      }
    }
  }, [data?.type]);

  const onSubmit = useCallback((data) => {
    createPromo(data)
      .then(() => {
        NotificationManager.success("Промокод успешно создан");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  if (products.loading || categories.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Создать промокод" />
      <div>
        <Link
          to="/sales/promos"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Создать промокод</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Тип"
                  title="Тип"
                  value={data?.type ?? promos[0].value}
                  data={promos}
                  onClick={(e) => setValue("type", e.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  label="Промокод"
                  name="title"
                  placeholder="Например `brithday`"
                  errors={errors}
                  register={register}
                  validation={{
                    required: "Обязательное поле",
                    maxLength: {
                      value: 100,
                      message: "Максимально 100 символов",
                    },
                  }}
                />
                <small className="text-muted fs-08">
                  Длина промокода не более 100 символов. Пробелы будут удалены.
                  Регистр не учитывается.
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Описание"
                  name="desc"
                  placeholder="Введите описание"
                  errors={errors}
                  register={register}
                  validation={{
                    maxLength: {
                      value: 250,
                      message: "Максимально 250 символов",
                    },
                  }}
                />
                <small className="text-muted fs-08">
                  Описание видит только администратор
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  label="Текст ошибки"
                  name="error"
                  placeholder="Введите текст ошибки"
                  errors={errors}
                  register={register}
                  validation={{
                    maxLength: {
                      value: 250,
                      message: "Максимально 250 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  type="number"
                  label="Минимальная сумма корзины"
                  name="options.minTotalCart"
                  defaultValue={
                    data.options?.minTotalCart ?? discount?.minOrderSum ?? 0
                  }
                  placeholder="0"
                  errors={errors}
                  register={register}
                  validation={{
                    max: {
                      value: 1000000,
                      message: "Максимально 1000000",
                    },
                  }}
                />
              </div>
            </Col>
            {(data.type === "birthday_list_gift" ||
              data.type === "birthday_cart") && (
              <>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      type="number"
                      label="Дней до"
                      name="options.dayBefore"
                      defaultValue={3}
                      placeholder="0"
                      errors={errors}
                      register={register}
                      validation={{
                        max: {
                          value: 90,
                          message: "Максимально 90",
                        },
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      type="number"
                      label="Дней после"
                      name="options.dayAfter"
                      defaultValue={3}
                      placeholder="0"
                      errors={errors}
                      register={register}
                      validation={{
                        max: {
                          value: 90,
                          message: "Максимально 90",
                        },
                      }}
                    />
                  </div>
                </Col>
              </>
            )}
            {data.type != "integration_coupon" &&
              data.type != "birthday_list_gift" && (
                <Col md={6}>
                  <div className="mb-4">
                    {module?.data?.relationModule?.options?.discounts?.length >
                      0 && (
                      <>
                        <Select
                          label="Скидка"
                          search
                          value={data?.options?.apiId}
                          onClick={(e) => setValue("options.apiId", e.value)}
                          data={module.data.relationModule.options.discounts.map(
                            (e) => ({
                              title: e.name,
                              value: e.id,
                            })
                          )}
                        />
                        <p className="text-muted fs-07 mt-1">
                          Для обновления списка, перейдите в модуль интеграции с
                          вашей системой учета
                        </p>
                      </>
                    )}
                  </div>
                </Col>
              )}
            {data.type == "integration_coupon" && (
              <Col md={6}>
                <div className="mb-4">
                  {module?.data?.relationModule?.options?.coupons?.length >
                    0 && (
                    <>
                      <Select
                        label="Купоны"
                        search
                        value={data?.options?.apiId}
                        onClick={(e) => setValue("options.apiId", e.value)}
                        data={module?.data?.relationModule?.options?.coupons.map(
                          (e) => ({
                            title: e.number,
                            value: e.id,
                          })
                        )}
                      />
                      <p className="text-muted fs-07 mt-1">
                        Для обновления списка, перейдите в модуль интеграции с
                        вашей системой учета
                      </p>
                    </>
                  )}
                </div>
              </Col>
            )}
            {data.type != "integration_coupon" &&
              data.type != "birthday_list_gift" && (
                <>
                  <Col md={6}>
                    <div className="mb-3">
                      <Input
                        type="number"
                        label="Процент скидки"
                        name="options.percent"
                        placeholder="0"
                        errors={errors}
                        register={register}
                        value={
                          discount?.percent
                            ? discount.percent
                            : discount?.productCategoryDiscounts &&
                              discount?.productCategoryDiscounts[0]?.percent
                            ? discount.productCategoryDiscounts[0].percent
                            : data.options?.percent ?? 0
                        }
                        validation={{
                          max: {
                            value: 100,
                            message: "Максимально 100 процентов",
                          },
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Input
                        type="number"
                        label="Сумма"
                        placeholder="0"
                        name="options.sum"
                        value={
                          discount?.sum ? discount?.sum : data.options?.sum ?? 0
                        }
                        errors={errors}
                        register={register}
                      />
                    </div>
                  </Col>
                </>
              )}

            <Col md={12}>
              <Form.Check className="mb-3 d-inline-flex me-4">
                <Form.Check.Input
                  type="checkbox"
                  name="status"
                  id="status"
                  defaultChecked={data.status}
                  {...register("status")}
                />
                <Form.Check.Label htmlFor="status" className="ms-2">
                  Активный промокод
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="mb-2 d-inline-flex ms-e">
                <Form.Check.Input
                  type="checkbox"
                  name="options.summed"
                  id="summed"
                  defaultChecked={data.options?.summed}
                  {...register("options.summed")}
                />
                <Form.Check.Label htmlFor="summed" className="ms-2">
                  Суммируется с акциями и скидками
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>

          {data.type == "weekend_cart" && (
            <Row className="mb-3">
              <Col md={12}>
                <h5 className="mb-2 mt-1">Время действия</h5>
                <p className="text-muted fs-09 mb-4">
                  Настройте время действия промокода
                </p>
                <div className="mb-3 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="me-2 work-label">Все</span>
                    <Input
                      className="me-2"
                      label="Начало"
                      type="time"
                      onChange={(event) =>
                        setValue(
                          "options.work",
                          data.options?.work?.length > 0
                            ? data.options.work.map((e) => ({
                                ...e,
                                start: event,
                              }))
                            : work.map((e) => ({
                                ...e,
                                start: event,
                              }))
                        )
                      }
                    />
                    <Input
                      label="Конец"
                      type="time"
                      onChange={(event) =>
                        setValue(
                          "options.work",
                          data.options?.work?.length > 0
                            ? data.options.work.map((e) => ({
                                ...e,
                                end: event,
                              }))
                            : work.map((e) => ({
                                ...e,
                                end: event,
                              }))
                        )
                      }
                    />
                  </div>
                  <div>
                    <Form.Check className="my-2 ms-2">
                      <Form.Check.Input
                        type="checkbox"
                        id="status-all"
                        checked={
                          data.options?.work &&
                          !!!data.options?.work.find((e) => !e.status)
                        }
                        onChange={(event) =>
                          setValue(
                            "options.work",
                            data.options?.work?.length > 0
                              ? data.options.work.map((e) => ({
                                  ...e,
                                  status: event.target.checked,
                                }))
                              : work.map((e) => ({
                                  ...e,
                                  status: event.target.checked,
                                }))
                          )
                        }
                      />
                      <Form.Check.Label htmlFor="status-all" className="ms-2">
                        Работает
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </div>
                <hr />
                <div className="mb-3 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="me-2 work-label">Пн -</span>
                    <Input
                      className="me-2"
                      label="Начало"
                      type="time"
                      name="options.work.0.start"
                      register={register}
                    />
                    <Input
                      label="Конец"
                      type="time"
                      name="options.work.0.end"
                      register={register}
                    />
                  </div>
                  <div>
                    <Form.Check className="my-2 ms-2">
                      <Form.Check.Input
                        type="checkbox"
                        id="status-0"
                        defaultChecked={true}
                        {...register("options.work.0.status")}
                      />
                      <Form.Check.Label htmlFor="status-0" className="ms-2">
                        Работает
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </div>
                <div className="mb-3 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="me-2 work-label">Вт -</span>
                    <Input
                      className="me-2"
                      label="Начало"
                      type="time"
                      name="options.work.1.start"
                      register={register}
                    />
                    <Input
                      label="Конец"
                      type="time"
                      name="options.work.1.end"
                      register={register}
                    />
                  </div>
                  <div>
                    <Form.Check className="my-2 ms-2">
                      <Form.Check.Input
                        type="checkbox"
                        id="status-1"
                        defaultChecked={true}
                        {...register("options.work.1.status")}
                      />
                      <Form.Check.Label htmlFor="status-1" className="ms-2">
                        Работает
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </div>
                <div className="mb-3 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="me-2 work-label">Ср -</span>
                    <Input
                      className="me-2"
                      label="Начало"
                      type="time"
                      name="options.work.2.start"
                      register={register}
                    />
                    <Input
                      label="Конец"
                      type="time"
                      name="options.work.2.end"
                      register={register}
                    />
                  </div>
                  <div>
                    <Form.Check className="my-2 ms-2">
                      <Form.Check.Input
                        type="checkbox"
                        id="status-2"
                        defaultChecked={true}
                        {...register("options.work.2.status")}
                      />
                      <Form.Check.Label htmlFor="status-2" className="ms-2">
                        Работает
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </div>
                <div className="mb-3 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="me-2 work-label">Чт -</span>
                    <Input
                      className="me-2"
                      label="Начало"
                      type="time"
                      name="options.work.3.start"
                      register={register}
                    />
                    <Input
                      label="Конец"
                      type="time"
                      name="options.work.3.end"
                      register={register}
                    />
                  </div>
                  <div>
                    <Form.Check className="my-2 ms-2">
                      <Form.Check.Input
                        type="checkbox"
                        id="status-3"
                        defaultChecked={true}
                        {...register("options.work.3.status")}
                      />
                      <Form.Check.Label htmlFor="status-3" className="ms-2">
                        Работает
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </div>
                <div className="mb-3 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="me-2 work-label">Пт -</span>
                    <Input
                      className="me-2"
                      label="Начало"
                      type="time"
                      name="options.work.4.start"
                      register={register}
                    />
                    <Input
                      label="Конец"
                      type="time"
                      name="options.work.4.end"
                      register={register}
                    />
                  </div>
                  <div>
                    <Form.Check className="my-2 ms-2">
                      <Form.Check.Input
                        type="checkbox"
                        id="status-4"
                        defaultChecked={true}
                        {...register("options.work.4.status")}
                      />
                      <Form.Check.Label htmlFor="status-4" className="ms-2">
                        Работает
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </div>
                <div className="mb-3 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="me-2 work-label">Сб -</span>
                    <Input
                      className="me-2"
                      label="Начало"
                      type="time"
                      name="options.work.5.start"
                      register={register}
                    />
                    <Input
                      label="Конец"
                      type="time"
                      name="options.work.5.end"
                      register={register}
                    />
                  </div>
                  <div>
                    <Form.Check className="my-2 ms-2">
                      <Form.Check.Input
                        type="checkbox"
                        id="status-5"
                        defaultChecked={true}
                        {...register("options.work.5.status")}
                      />
                      <Form.Check.Label htmlFor="status-5" className="ms-2">
                        Работает
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <span className="me-2 work-label">Вс -</span>
                    <Input
                      className="me-2"
                      label="Начало"
                      type="time"
                      name="options.work.6.start"
                      register={register}
                    />
                    <Input
                      label="Конец"
                      type="time"
                      name="options.work.6.end"
                      register={register}
                    />
                  </div>
                  <div>
                    <Form.Check className="my-2 ms-2">
                      <Form.Check.Input
                        type="checkbox"
                        id="status-6"
                        defaultChecked={true}
                        {...register("options.work.6.status")}
                      />
                      <Form.Check.Label htmlFor="status-6" className="ms-2">
                        Работает
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </div>
              </Col>
            </Row>
          )}

          {(data.type == "product_order_one" || data.type == "product_one") && (
            <>
              <h5 className="mb-4">Товар</h5>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Select
                      label="Товар"
                      search
                      value={data.options?.productId}
                      data={products.items}
                      onClick={(e) => setValue("options.productId", e.value)}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      type="number"
                      label="Цена"
                      name="options.productPrice"
                      placeholder="0"
                      errors={errors}
                      register={register}
                      validation={{
                        max: {
                          value: 99999,
                          message: "Максимально 99999",
                        },
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
          {data.type == "category_one" && (
            <>
              <Row>
                <Col md={6}>
                  <h5 className="mb-4">Категория</h5>
                  <div className="mb-3">
                    <Select
                      label="Категория"
                      search
                      value={data.options?.categoryId}
                      data={[
                        { title: "Все", value: null },
                        ...categories.items,
                      ]}
                      onClick={(e) => setValue("options.categoryId", e.value)}
                    />
                  </div>
                  {!data.options?.categoryId && (
                    <>
                      <h5 className="mb-4">Исключения</h5>
                      <div className="box box-gray mb-3 body-scroll">
                        {categories.items?.length > 0 &&
                          categories.items.map((category, index) => (
                            <Form.Check key={index} className="mb-3">
                              <Form.Check.Input
                                type="checkbox"
                                name="options.exceptions"
                                id={"exceptions-" + category.value}
                                value={category.value}
                                defaultChecked={
                                  data?.exceptions?.length > 0 &&
                                  data.exceptions.findIndex(
                                    (e) => e.id === category.value
                                  ) != -1
                                }
                                {...register("options.exceptions")}
                              />
                              <Form.Check.Label
                                htmlFor={"exceptions-" + category.value}
                                className="ms-2"
                              >
                                {category.title}
                              </Form.Check.Label>
                            </Form.Check>
                          ))}
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default PromoCreate;
