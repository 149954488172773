import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  IoAdd,
  IoCloseOutline,
  IoCreateOutline,
  IoDownloadOutline,
  IoEllipsisVertical,
  IoSaveOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { customPrice } from "../../helpers/product";
import { getCategories } from "../../services/category";
import {
  copyProduct,
  deleteProduct,
  deleteProductAll,
  getProducts,
  getProductDownload,
} from "../../services/product";
import Textarea from "../../components/UI/Textarea";

const Products = () => {
  const { t } = useTranslation();
  const inputRef = createRef();
  const affiliate = useSelector((state) => state.affiliate.active);
  const brand = useSelector((state) => state.brand.active);
  const user = useSelector((state) => state.auth.user);
  const [searchParams, setSearchParams] = useSearchParams();

  const [products, setProducts] = useState({
    loading: true,
    items: [],
  });
  const [categories, setCategories] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });
  const [modalCopy, setModalCopy] = useState({
    show: false,
    data: {
      id: false,
      value: "",
      deleteProduct: false,
      status: 1,
      title: null,
      description: null,
      titleOld: null,
    },
  });
  const [modalDeleteAll, setModalDeleteAll] = useState(false);
  const [loadFile, setLoadFile] = useState(false);

  const productColumns = [
    {
      name: "Фото",
      width: "60px",
      selector: "media",
      align: "center",
      cell: (row) => (
        <Link to={"/catalog/product/" + row.id}>
          <img
            src={getImageURL({ path: row?.medias })}
            width={30}
            height={30}
            className="mini-img"
          />
        </Link>
      ),
    },
    {
      name: "Название",
      selector: "title",
      cell: (row) => <Link to={"/catalog/product/" + row.id}>{row.title}</Link>,
    },
    {
      name: "Категория",
      selector: "category",
      cell: (row) => row?.category?.title ?? null,
    },
    {
      name: "Артикул",
      selector: "code",
      cell: (row) => (
        <Link to={"/catalog/product/" + row.id}>
          {row?.code?.length > 0 ? row.code : "-"}
        </Link>
      ),
      width: "150px",
    },
    {
      name: "Дата",
      selector: "createdAt",
      cell: (row) => (
        <Link to={"/catalog/product/" + row.id}>
          <div>{moment(row.createdAt).format("DD.MM")}</div>
          <div className="fw-7">{moment(row.createdAt).format("HH:mm")}</div>
        </Link>
      ),
      width: "60px",
    },
    {
      name: "Стоимость",
      selector: "price",
      align: "right",
      cell: (row) => {
        return row?.modifiers?.length > 0 &&
          Math.min.apply(
            null,
            row.modifiers.map((e) => e.price)
          ) > 0
          ? "от " +
              (row?.options?.modifierPriceSum
                ? customPrice(
                    Number(row.price) +
                      Number(
                        Math.min.apply(
                          null,
                          row.modifiers.map((e) => e.price)
                        )
                      ),
                    false,
                    user?.lang
                  )
                : customPrice(
                    Math.min.apply(
                      null,
                      row.modifiers.map((e) => e.price)
                    ),
                    false,
                    user?.lang
                  ))
          : customPrice(row.price, false, user?.lang);
      },
      width: "120px",
    },
    {
      width: "100px",
      name: "Статус",
      align: "center",
      selector: "status",
      cell: (row) =>
        row.status === 0 ? (
          <Badge bg="secondary">{t("Архив")}</Badge>
        ) : (
          <Badge bg="success">{t("Активно")}</Badge>
        ),
    },
    {
      width: "40px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle
            as={React.forwardRef(({ children, onClick }, ref) => (
              <a
                ref={ref}
                className="py-0"
                onClick={(e) => {
                  e.preventDefault();
                  onClick(e);
                }}
              >
                <IoEllipsisVertical size={20} />
              </a>
            ))}
          />
          <Dropdown.Menu align="end">
            <Dropdown.Item
              as={Link}
              disabled={selected.length > 0}
              to={"/catalog/product/" + row.id}
            >
              {t("Изменить")}
            </Dropdown.Item>
            <Dropdown.Item
              disabled={selected.length > 0}
              onClick={() =>
                setModalCopy((prev) => ({
                  ...prev,
                  show: true,
                  data: {
                    ...prev.data,
                    id: row.id,
                    titleOld: row?.title,
                    title: row?.title,
                    description: row?.description,
                  },
                }))
              }
            >
              {t("Дублировать")}
            </Dropdown.Item>
            <Dropdown.Item
              className="text-danger"
              disabled={selected.length > 0}
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: row.id })
              }
            >
              {t("Удалить")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const getData = useCallback(async () => {
    if (affiliate?.id) {
      searchParams.set("affiliateId", affiliate.id);
    } else {
      searchParams.delete("affiliateId");
    }
    getProducts(searchParams)
      .then(
        (res) =>
          res &&
          setProducts((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setProducts((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand, affiliate]);

  const onCopy = useCallback(() => {
    copyProduct(modalCopy.data)
      .then(() => {
        getData();
        NotificationManager.success(
          t(
            modalCopy.data?.deleteProduct
              ? "Товар успешно перенесен"
              : "Товар успешно продублирован"
          )
        );
        setModalCopy((prev) => ({
          ...prev,
          show: false,
          data: { value: "", id: false, deleteProduct: false },
        }));
      })
      .catch(() => NotificationManager.error(t("Ошибка при запросе")));
  }, [modalCopy.data]);

  const onDelete = useCallback((id) => {
    deleteProduct(id)
      .then(() => {
        getData();
        NotificationManager.success(t("Товар успешно удален"));
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error(t("Ошибка при запросе")));
  }, []);

  const onDeleteAll = useCallback(() => {
    deleteProductAll()
      .then(() => {
        getData();
        NotificationManager.success(t("Товары успешно удалены"));
        setModalDeleteAll(false);
      })
      .catch(() => NotificationManager.error(t("Ошибка при запросе")));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteProduct(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success(t("Выбранные товары успешно удалены"));
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error(t("Ошибка при запросе")));
  }, [selected]);

  const onProductDownload = useCallback((type) => {
    setLoadFile(true);
    getProductDownload(type)
      .then(async (res) => {
        NotificationManager.success(t("Идет генерация файла..."));
        const filename = `products_${moment().format(
          "YYYY_MM_DD_kk_mm_ss"
        )}.${type}`;

        // Получаем Blob из ответа
        const blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        // Создаем URL для Blob
        const url = window.URL.createObjectURL(blob);

        // Создаем элемент ссылки для скачивания
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;

        // Добавляем ссылку на документ и инициируем клик
        document.body.appendChild(a);
        a.click();

        // Удаляем ссылку после скачивания
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setTimeout(() => setLoadFile(false), 2000);
      })
      .catch((err) => {
        setLoadFile(false);
        NotificationManager.error(t("Ошибка при загрузке"));
      });
  }, []);

  const onSearch = useCallback(() => {
    getData();
  }, [
    searchParams.get("text"),
    searchParams.get("categoryId"),
    searchParams.get("size"),
  ]);

  useLayoutEffect(() => {
    getCategories({ size: 350, list: true })
      .then((res) => {
        if (res?.items?.length > 0) {
          let array = res.items.map((e) => ({ title: e.title, value: e.id }));
          array.unshift({
            title: "Все",
            value: "",
          });
          setCategories((prev) => ({
            ...prev,
            loading: false,
            items: array,
          }));
        }
      })
      .finally(() => setCategories((prev) => ({ ...prev, loading: false })));
  }, [brand]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), brand, affiliate]);

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0
                ? `${t("Выбрано")} ${selected.length}`
                : t("Товары")}
            </h5>
          </div>
          <div className="d-flex align-items-center">
            <Dropdown>
              <Dropdown.Toggle
                as={React.forwardRef(({ children, onClick }, ref) => (
                  <a
                    ref={ref}
                    className="btn-primary-outline me-3"
                    onClick={(e) => {
                      e.preventDefault();
                      onClick(e);
                    }}
                  >
                    <IoSaveOutline size={20} />
                  </a>
                ))}
              />
              <Dropdown.Menu align="end">
                <Dropdown.Item onClick={() => onProductDownload("yml")}>
                  YML
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onProductDownload("csv")}>
                  CSV
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onProductDownload("json")}>
                  JSON
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Link
              to="/catalog/products/create"
              className="btn-primary-outline me-3"
            >
              <IoAdd size={18} />
            </Link>
            <Button
              className="btn-light"
              onClick={() =>
                selected.length > 0
                  ? setModalDelete({ show: true, id: false })
                  : setModalDeleteAll(true)
              }
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Статус"
                data={[
                  { title: "Все", value: "" },
                  { title: "Скрытые из каталога", value: "noview" },
                  { title: "Без категории", value: "nocategory" },
                  { title: "Без фото", value: "nomedia" },
                  { title: "Без суммы", value: "noprice" },
                  { title: "С модификаторами", value: "modifier" },
                ]}
                value={searchParams.get("status") ?? ""}
                onClick={(e) => {
                  searchParams.set("status", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Сортировка"
                data={[
                  { title: "По дате добавления: новые", value: "createnew" },
                  { title: "По дате добавления: старые", value: "createold" },
                  { title: "По дате обновления: новые", value: "updatenew" },
                  { title: "По дате обновления: старые", value: "updateold" },
                  { title: "Стоимость: больше", value: "pricemore" },
                  { title: "Стоимость: меньше", value: "priceless" },
                ]}
                value={searchParams.get("sort") ?? "createnew"}
                onClick={(e) => {
                  searchParams.set("sort", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Категория"
                data={categories.items}
                value={
                  searchParams.get("categoryId")
                    ? Number(searchParams.get("categoryId"))
                    : ""
                }
                onClick={(e) => {
                  searchParams.set("categoryId", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Показать"
                data={[
                  { title: "25", value: "" },
                  { title: "50", value: 50 },
                  { title: "Все", value: 1000 },
                ]}
                value={
                  searchParams.get("size")
                    ? Number(searchParams.get("size"))
                    : ""
                }
                onClick={(e) => {
                  if (e.value > 0) {
                    searchParams.set("size", e.value);
                  } else {
                    searchParams.delete("size");
                  }
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={12}>
              <Input
                ref={inputRef}
                placeholder={t("Найти")}
                className="w-100"
                onChange={(e) => {
                  if (e.length > 0) {
                    searchParams.set("text", e);
                  } else {
                    searchParams.delete("text");
                  }
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                value={searchParams.get("text") ?? ""}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
              {searchParams.get("text")?.length > 0 && (
                <Button
                  className="btn-light ms-3"
                  onClick={() => {
                    searchParams.delete("text");
                    setSearchParams(searchParams);
                    onSearch();
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <IoCloseOutline size={22} />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, categories, modalDelete, t]);

  if (products.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title={t("Товары")} />
      <DataTable
        columns={productColumns}
        onChange={(items) => setSelected(items)}
        data={products.items}
        updateData={user}
        header={header}
        selectable
        pagination={products.pagination}
      />
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : t("Удаление элемента")
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className="me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              {t("Отмена")}
            </Button>
            <Button
              className="btn-danger"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              {t("Удалить")}
            </Button>
          </>
        }
      >
        {t("Вы точно хотите удалить товар(-ы)?")}
      </CustomModal>
      <CustomModal
        title={t("Удалить все товары")}
        show={modalDeleteAll}
        setShow={(e) => setModalDeleteAll(e)}
        footer={
          <>
            <Button className=" me-3" onClick={() => setModalDeleteAll(false)}>
              {t("Отмена")}
            </Button>
            <Button className="btn-primary" onClick={() => onDeleteAll()}>
              {t("Удалить все")}
            </Button>
          </>
        }
      >
        {t("Вы точно хотите удалить все товары?")}
      </CustomModal>
      <CustomModal
        title={t("Дублировать товар")}
        show={modalCopy.show}
        setShow={(e) =>
          setModalCopy((prev) => ({ ...prev, value: "", show: e }))
        }
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalCopy((prev) => ({ ...prev, value: "", show: false }))
              }
            >
              {t("Отмена")}
            </Button>
            <Button className="btn-primary" onClick={() => onCopy()}>
              {t(modalCopy.data?.deleteProduct ? "Перенести" : "Дублировать")}
            </Button>
          </>
        }
      >
        <div className="mb-4">
          <Input
            label="Новое название"
            placeholder={t("Название")}
            className="w-100"
            onChange={(e) => {
              setModalCopy((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  title: e,
                },
              }));
            }}
            value={modalCopy.data?.title}
          />
          {modalCopy.data?.titleOld != modalCopy.data?.title && (
            <div className="d-block fs-08 mt-1">
              <span className="text-muted">Старое название:</span>{" "}
              <span className="text-success">{modalCopy.data.titleOld}</span>
            </div>
          )}
        </div>
        <Textarea
          label="Новое описание"
          placeholder={t("Описание")}
          className="w-100 mb-3"
          onChange={(e) => {
            setModalCopy((prev) => ({
              ...prev,
              data: {
                ...prev.data,
                description: e,
              },
            }));
          }}
          defaultValue={modalCopy.data?.description}
        />
        <Select
          classNameContainer="w-100 mb-3"
          label="Категория"
          data={categories.items}
          value={modalCopy.data?.categoryId}
          onClick={(e) => {
            setModalCopy((prev) => ({
              ...prev,
              data: {
                ...prev.data,
                categoryId: e.value,
              },
            }));
          }}
        />
        <Select
          label="Раздел"
          classNameContainer="w-100 mb-3"
          data={[
            { title: "Товары", value: "" },
            { title: "Модификаторы", value: "modifier" },
          ]}
          value={modalCopy.data?.value}
          onClick={(e) => {
            setModalCopy((prev) => ({
              ...prev,
              data: {
                ...prev.data,
                value: e.value,
              },
            }));
          }}
        />
        {!modalCopy.data?.deleteProduct && (
          <>
            <Select
              label="Статус"
              classNameContainer="w-100"
              data={[
                { title: "Активный", value: 1 },
                { title: "Скрытый", value: 0 },
              ]}
              value={modalCopy.data?.status}
              onClick={(e) => {
                setModalCopy((prev) => ({
                  ...prev,
                  data: {
                    ...prev.data,
                    status: e.value,
                  },
                }));
              }}
            />
            <small className="text-muted d-block fs-08 mt-1">
              Вы можете скрыть товар, после дублирования
            </small>
          </>
        )}
        <Form.Check className="mt-3 d-inline-block">
          <Form.Check.Input
            type="checkbox"
            id="copy-delete"
            defaultChecked={modalCopy.data?.deleteProduct}
            onChange={() =>
              setModalCopy((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  delete: !prev.data?.deleteProduct,
                },
              }))
            }
          />
          <Form.Check.Label htmlFor="copy-delete" className="ms-2">
            Удалить после дублирования
          </Form.Check.Label>
        </Form.Check>
      </CustomModal>
      <CustomModal
        title={t("Выгрузка товаров")}
        show={loadFile}
        backdrop="static"
        closeButton={false}
        setShow={(e) => setLoadFile(e)}
      >
        <div className="fs-11">
          {t(
            "Идет загрузка товаров из базы данных и создание файла, ожидайте (до 30 сек)..."
          )}
        </div>
      </CustomModal>
    </>
  );
};

export default Products;
