import { createSlice } from "@reduxjs/toolkit";
import { generateKey } from "../../helpers/all";

const initialState = {
  isConnected: true,
  ip: null,
  apiId: null,
  brand: false,
  audio: true,
  source: false,
  options: {
    defaultContinent: null,
    defaultCountry: null,
    defaultCity: null,
    defaultCoordinates: { lat: null, lon: null },
  },
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateApiId: (state, action) => {
      state.apiId = action.payload;
    },
    updateSource: (state, action) => {
      state.source = action.payload;
    },
    updateConnect: (state, action) => {
      state.isConnected = action.payload;
    },
    updateOptions: (state, action) => {
      state = { ...state, options: action.payload };

      if (!state?.apiId || state?.apiId?.length === 0) {
        state.apiId = generateKey(100)
      }

      return state;
    },
    updateIp: (state, action) => {
      state.ip = action.payload;
    },
    updateBrand: (state, action) => {
      state.brand = action.payload;
    },
    updateAudio: (state, action) => {
      state.audio = action.payload;
    },
  },
});

export const {
  updateSource,
  updateConnect,
  updateAudio,
  updateOptions,
  updateIp,
  updateBrand,
  updateApiId,
} = settingsSlice.actions;

export default settingsSlice.reducer;
