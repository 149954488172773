import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingLogin: false,
  isAuth: false,
  user: {},
  token: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAuth: (state, action) => {
      state.isAuth = action.payload;
    },
  }
});

export const { setUser, setAuth, setToken } = authSlice.actions;

export default authSlice.reducer;
