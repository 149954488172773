import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  active: false,
};

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    setBrands: (state, action) => {
      state.data = action.payload;
      state.active =
        action.payload?.length > 0
          ? !state?.active || !state?.active?.id
            ? action.payload.find((e) => e.main)
            : state.active
          : false;
    },
    mainBrandEdit: (state, action) => {
      let data = state.data.map((e) => {
        e.main = e.id === action?.payload;
        return e;
      });
      state.data = data;
      state.active = data.find((e) => e.main);
    },
    updateBrand: (state, action) => {
      let data = state.data.map((e) => {
        if (e.id === action?.payload?.id) {
          e = action.payload;
        }
        return e;
      });
      state.data = data;
      state.active = data.find((e) => e.main);
    },
    resetBrand: (state, action) => {
      return { ...initialState };
    },
  },
});

export const { setBrands, updateBrand, mainBrandEdit, resetBrand } =
  brandSlice.actions;

export default brandSlice.reducer;
