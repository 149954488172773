import React, { useCallback, useLayoutEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { weightFoodTypes, weightShopTypes } from "../../helpers/product";
import { getCategories } from "../../services/category";
import { createModifier } from "../../services/modifier";

const ModifierCreate = () => {
  const { t } = useTranslation();
  const brand = useSelector((state) => state.brand.active);
  const [categories, setCategories] = useState({
    loading: true,
    items: [],
  });
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      type: "modifier",
      energy: {
        weight: 0,
        weightType: "г",
      },
    },
  });

  const data = useWatch({ control });

  useLayoutEffect(() => {
    getCategories({ size: 250 })
      .then((res) => {
        setCategories((prev) => ({ ...prev, loading: false, ...res }));
      })
      .catch(() => setCategories((prev) => ({ ...prev, loading: false })));
  }, []);

  const onSubmit = useCallback((data) => {
    createModifier(data)
      .then(() => NotificationManager.success("Модификатор успешно создан"))
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  // const FormIngredient = ({ data, onChange }) => {
  //   return (
  //     <Row>
  //       <Col md={6}>
  //         <div className="mb-3">
  //           <Input
  //             defaultValue={data.weight ?? 0}
  //             label="Вес, г"
  //             name="weight"
  //             type="number"
  //             onChange={(e) => onChange && onChange("weight", e)}
  //           />
  //         </div>
  //       </Col>
  //       <Col md={6}>
  //         <div className="mb-3">
  //           <Input
  //             defaultValue={data.price ?? 0}
  //             label="Цена"
  //             type="number"
  //             name="price"
  //             onChange={(e) => onChange && onChange("price", e)}
  //           />
  //         </div>
  //       </Col>
  //     </Row>
  //   );
  // };

  return (
    <>
      <Meta title="Создать модификатор" />
      <div>
        <Link
          to="/catalog/modifiers"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <h3 className="mb-4">Создать модификатор</h3>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Row>
                <Col md={8}>
                  <div className="mb-3">
                    <Input
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Input
                      label="Артикул"
                      name="code"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="pb-3">
                    <Input
                      label="Доп поле"
                      name="options.titleOld"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={0}
                      label="Порядок"
                      name="priority"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Select
                      classNameContainer="w-100"
                      label="Тип"
                      data={[
                        { title: "Модификатор", value: "modifier" },
                        { title: "Добавка", value: "addition" },
                        { title: "Пожелание", value: "wish" },
                      ]}
                      value={data.type ?? null}
                      onClick={(e) => {
                        setValue("type", e.value);
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Select
                      classNameContainer="w-100"
                      label="Тип отправки"
                      data={[
                        { title: "Модификатор", value: "modifier" },
                        { title: "Добавка", value: "addition" },
                      ]}
                      value={data.options?.typeSend ?? "modifier"}
                      onClick={(e) => {
                        setValue("options.typeSend", e.value);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Select
                      label="Категория"
                      search
                      value={data.categoryId}
                      data={categories.items.map((e) => ({
                        title: e.title,
                        value: e.id,
                      }))}
                      onClick={(e) => setValue("categoryId", e.value)}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Textarea
                      defaultValue={data.description}
                      label="Описание"
                      name="description"
                      errors={errors}
                      rows={6}
                      register={register}
                    />
                  </div>
                </Col>
                {data?.options?.apiId && (
                  <Col md={12}>
                    <Input
                      defaultValue={data.options.apiId}
                      label="API ID"
                      readOnly={false}
                    />
                  </Col>
                )}
                <Col md={6}>
                  <Form.Check className="mb-3 d-inline-block">
                    <Form.Check.Input
                      type="checkbox"
                      id="optional"
                      defaultChecked={!!data?.options?.optional}
                      {...register("options.optional")}
                    />
                    <Form.Check.Label htmlFor="optional" className="ms-2">
                      Необязательный модификатор
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col md={6}>
                  <Form.Check className="mb-3 d-inline-block">
                    <Form.Check.Input
                      type="checkbox"
                      name="main"
                      id="main"
                      defaultChecked={!!data.main}
                      {...register("main")}
                    />
                    <Form.Check.Label htmlFor="main" className="ms-2">
                      По умолчанию
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="position-relative">
          <div className="position-sticky top-1">
            {/* <Card className="mb-3" body>
              <DragDropFile
                file={data.file}
                media={data.medias}
                onChange={(e) =>
                  setEditImageProduct((info) => ({
                    show: !info.show,
                    data: e,
                  }))
                }
              />
              <DragDropFile
                col={12}
                maxAspect={1}
                main
                multiple
                file={data.file}
                onMain={(e) => setValue("main", e)}
                onChange={(e) => setValue("file", e)}
                onDelete={(e) => setValue("file", e.file)}
              />
            </Card> */}
            <Card body className="mb-3">
              <p className="fs-09 fw-6">Состав (КБЖУ)</p>
              {/* <p className="fs-08">
                  Энергетическая ценность в 100г -{" "}
                  <span className="text-success">
                    {kkalData?.current?.kkal ?? 0}
                  </span>{" "}
                  ккал
                </p> */}
              <Row className="gx-2">
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      label="Белки, г"
                      defaultValue={data?.energy?.protein ?? 0}
                      name="energy.protein"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      label="Жиры, г"
                      defaultValue={data?.energy?.fat ?? 0}
                      name="energy.fat"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      label="Углеводы, г"
                      defaultValue={data?.energy?.carbohydrate ?? 0}
                      name="energy.carbohydrate"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="gx-2">
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      label="Всего ккал, г"
                      defaultValue={data?.energy?.kkal ?? 0}
                      name="energy.kkal"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      defaultValue={data?.energy?.weight ?? 0}
                      label="Вес, г"
                      name="energy.weight"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Select
                      label="Единица измерения"
                      title="г, кг, т ..."
                      value={data?.energy?.weightType ?? "г"}
                      data={
                        brand?.type == "food"
                          ? weightFoodTypes
                          : weightShopTypes
                      }
                      onClick={(e) => setValue("energy.weightType", e.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Card>

            <Card body className="mb-3">
              <p className="fs-08">{t("Цена")}</p>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      value={data.price}
                      label="Цена"
                      name="price"
                      min={0}
                      type="number"
                      onChange={(value) => {
                        setValue("price", value);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      value={data.discount}
                      label="Скидка"
                      name="discount"
                      min={0}
                      type="number"
                      onChange={(value) => {
                        setValue("discount", value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card>

            <Button
              className="btn-primary align-self-end w-100 mt-3"
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ModifierCreate;
