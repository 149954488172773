import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiRoutes, BASE_URL } from "../config/api";
import socket from "../config/socket";
import { $api, $authApi } from "./index";
import { setAuth, setToken, setUser } from "../store/reducers/authSlice";
import { resetBrand } from "../store/reducers/brandSlice";
import { resetAffiliate } from "../store/reducers/affiliateSlice";
import { resetStatus } from "../store/reducers/statusSlice";

const login = async (payloads) => {
  const response = await $api.post(apiRoutes.AUTH_LOGIN, payloads);
  return response?.data;
};

const logout = createAsyncThunk("auth/logout", async (navigate, thunkAPI) => {
  try {
    socket.disconnect();
    const response = await $authApi.post(apiRoutes.AUTH_LOGOUT);
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  } finally {
    // Очистка состояния в любом случае
    thunkAPI.dispatch(setAuth(false));
    thunkAPI.dispatch(setUser(false));
    thunkAPI.dispatch(setToken(false));
    thunkAPI.dispatch(resetBrand());
    thunkAPI.dispatch(resetAffiliate());
    thunkAPI.dispatch(resetStatus());

    // Редирект на главную страницу без обновления

    setTimeout(() => navigate ? navigate("/") : (window.location.href = "/"), 1500);
  }
});

const checkAuth = async () => {
  const response = await $authApi.post(apiRoutes.AUTH_CHECK);
  return response?.data;
};
const refreshAuth = async () => {
  try {
    const response = await $authApi.post(apiRoutes.AUTH_REFRESH);

    if (!response.data?.token || !response.data?.refreshToken) {
      throw new Error("Invalid tokens received");
    }

    return response.data;
  } catch (error) {
    console.error("Refresh token failed:", error);
    throw error;
  }
};
const authRegister = async (payloads = {}) => {
  const response = await axios.post(
    `${BASE_URL}${apiRoutes.AUTH_REGISTRATION}`,
    payloads
  );
  return response?.data;
};

const authActivate = async (payloads = {}) => {
  const response = await axios.post(
    `${BASE_URL}${apiRoutes.AUTH_ACTIVATE}`,
    payloads
  );
  return response?.data;
};

const authPasswordRecovery = async (payloads = {}) => {
  const response = await axios.post(
    `${BASE_URL}${apiRoutes.AUTH_RECOVERY}`,
    payloads
  );
  return response?.data;
};

export {
  authRegister,
  authActivate,
  authPasswordRecovery,
  login,
  logout,
  checkAuth,
  refreshAuth,
};
